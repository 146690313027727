import React, {useState} from "react";
import OrderFormElements from "../parts/form-elements/order-form-elements";
import {FormValues, UserData} from "../../types/types";
import CalculatorService from "../../services/calculator-service";
import {Redirect, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import { StaticContext } from 'react-router';
import {DefaultValues} from "react-hook-form";
import {useLocation} from "react-router-dom";
import SaveSystemService from "../../services/save-system-service";

type LocationState = {
    savedOrder: DefaultValues<FormValues>,
    mode: string
}
type OrderFormProps = {
    userData: UserData
    callPopup: (message: string, type: string) => void,
    isAuthorised: boolean
}

const OrderForm: React.FunctionComponent<OrderFormProps & RouteComponentProps<{}, StaticContext, LocationState>> = ({userData, callPopup, isAuthorised}: OrderFormProps, prop: RouteComponentProps<{}, StaticContext, LocationState>) =>  {

    let history = useHistory()
    const location = useLocation<LocationState>()

    if (!isAuthorised){
        return <Redirect to='/'/>
    }

    const onSubmit = async (data: FormValues) => {
        const Calculator = await CalculatorService.create(userData)
        Calculator.dataInitialization(data).then((items) => {
            history.push({pathname: '/order-summary',state:{items: items, userData: userData, formData: data, mode: 'normal', isAuthorised: isAuthorised, callPopup: callPopup}})
        })
    }

    if (location.state && location.state.mode === 'recalculate') {
        CalculatorService.create(userData).then((Calculator) => {
            if(location.state.savedOrder.items !== undefined){
                // @ts-ignore
                Calculator.dataInitialization(location.state.savedOrder).then((items) => {
                    history.push({pathname: '/order-summary',state:{items: items, userData: userData, mode: 'recalculate', formData: location.state.savedOrder, isAuthorised: isAuthorised, callPopup: callPopup}})
                })
            }
        })
    }

    if (location.state && location.state.mode === 'normal') {
        return <OrderFormElements callPopup={callPopup} mail={userData.mail} savedOrderData={location.state.savedOrder} onSubmit={onSubmit} />
    }

    return <OrderFormElements callPopup={callPopup} mail={userData.mail} onSubmit={onSubmit} />
}

export default withRouter(OrderForm)