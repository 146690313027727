import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import SaveSystemService from "../../../services/save-system-service";
import {watch} from "fs";

type props = {
    callPopup: (message: string, type: string) => void
}
type form = {
    data: { producer: string, cf: number}[],
}

const AdminChangeAutCf = ({callPopup}: props) => {
    const [cfList, setCfList] = useState([])

    const { register, handleSubmit, control } = useForm<form>({
        mode: "onChange",
        shouldUnregister: true
    })
    const { fields, append, remove, update } = useFieldArray({
        name: "data",
        control
    })

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    useEffect(() => {
        loadArticles()
    }, [])

    const loadArticles = () => {
        const SaveSystem = new SaveSystemService()
        SaveSystem.getAutCf().then((cf) => {
            console.log(cfList)
            console.log(cf)
            setCfList(cf)

            cf.forEach((item: { producer: string; cf: number }) => {
                append({ producer: item.producer, cf: item.cf });
            });
        })
    }

    const onSubmit = (values: form) => {
        const {data} = values

        const request = {
            data: data.map((item) => {
                return {
                    producer: item.producer,
                    cf: item.cf
                }
            })
        }
        console.log(request)

        api.post('/changeAutCf.php', request).then((res) => {
            if (res.data === 'Готово') {
                callPopup('Коэф. успешно изменены!', 'success')
            } else {
                callPopup(`Коэф. не были изменены! Ошибка: ${res.data}`, 'error')
            }
        })
    }

    return (
        <div>
            {(cfList.length > 0) &&
                (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {fields.map((item, index) => {
                                return (
                                    <div className="flex flex-row mb-5" key={item.id}>
                                        <span className="w-48">{item.producer}:</span>
                                        <input className='w-48' {...register(`data.${index}.cf`)} />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit">Сохранить
                            </button>
                        </div>
                    </form>
                )}
        </div>
    )
}

export default AdminChangeAutCf