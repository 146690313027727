import axios from "axios";
import {DefaultValues} from "react-hook-form";
import {AutCf, FormValues} from "../types/types";

const api = axios.create({
    baseURL: 'https://wholesale.markiz.by/'
})

export default class SaveSystemService {

    async getModelCf() {
        let res = await api.post('/getModelCf.php')
        console.log(res)
        if (res.data.error == null) {
            const response = {
                classic: {
                    constr: parseFloat(res.data[0].constr),
                    aut: parseFloat(res.data[0].aut)
                },
                modern: {
                    constr: parseFloat(res.data[1].constr),
                    aut: parseFloat(res.data[1].aut)
                },
                optima: {
                    constr: parseFloat(res.data[2].constr),
                    aut: parseFloat(res.data[2].aut)
                },
                optima_plus: {
                    constr: parseFloat(res.data[3].constr),
                    aut: parseFloat(res.data[3].aut)
                },
                viktory: {
                    constr: parseFloat(res.data[4].constr),
                    aut: parseFloat(res.data[4].aut)
                },
                helix: {
                    constr: parseFloat(res.data[5].constr),
                    aut: parseFloat(res.data[5].aut)
                },
                garmonia: {
                    constr: parseFloat(res.data[6].constr),
                    aut: parseFloat(res.data[6].aut)
                },
                domea: {
                    constr: parseFloat(res.data[7].constr),
                    aut: parseFloat(res.data[7].aut)
                },
                italia: {
                    constr: parseFloat(res.data[8].constr),
                    aut: parseFloat(res.data[8].aut)
                },
                asn130: {
                    constr: parseFloat(res.data[9].constr),
                    aut: parseFloat(res.data[9].aut)
                },
                asn110: {
                    constr: parseFloat(res.data[10].constr),
                    aut: parseFloat(res.data[10].aut)
                },
                tenz: {
                    constr: parseFloat(res.data[11].constr),
                    aut: parseFloat(res.data[11].aut)
                },
                asn130Eko: {
                    constr: parseFloat(res.data[12].constr),
                    aut: parseFloat(res.data[12].aut)
                },
                traffico: {
                    constr: parseFloat(res.data[13].constr),
                    aut: parseFloat(res.data[13].aut)
                }
            }
            console.log(response)
            return response
        } else {
            console.log('Error popup')
            return {
                classic: {
                    constr: 1.1709,
                    aut: 1
                },
                modern: {
                    constr: 1.1709,
                    aut: 1
                },
                optima: {
                    constr: 1.2214,
                    aut: 1
                },
                optima_plus: {
                    constr: 1.2214,
                    aut: 1
                },
                viktory: {
                    constr: 1.1415,
                    aut: 1
                },
                helix: {
                    constr: 1.2214,
                    aut: 1
                },
                garmonia: {
                    constr: 1.1415,
                    aut: 1
                },
                domea: {
                    constr: 1.1415,
                    aut: 1
                },
                italia: {
                    constr: 1.2214,
                    aut: 1
                },
                asn130: {
                    constr: 1.1709,
                    aut: 1
                },
                asn110: {
                    constr: 1.5807, //1.1709
                    aut: 1
                },
                tenz: {
                    constr: 1.07,
                    aut: 1
                },
                asn130Eko: {
                    constr: 1,
                    aut: 1
                },
                traffico: {
                    constr: 1,
                    aut: 1
                }
            }
        }
    }

    async getAutomatica() {
        let res = await api.post('/getAutomatica.php')
        if (res.data.error == null) {
            return res.data.map((item: { model: string }) => item.model)
        } else {
            console.log('Error popup')
            return false
        }
    }

    async getAutomaticaForAdmin() {
        let res = await api.post('/getAutomatica.php')
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

    async getModelCfForAdmin() {
        let res = await api.post('/getModelCf.php')
        console.log(res)
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

    async getAutomationCf() {
        let res = await api.post('/getAutomationCf.php')
        console.log(res)
        if (res.data.error == null) {
            const response: AutCf = {
                somfy: parseFloat(res.data[0].cf),
                cherubini: parseFloat(res.data[1].cf),
                ac: parseFloat(res.data[2].cf),
                traffico: parseFloat(res.data[3].cf)
            }
            console.log(response)
            return response
        } else {
            console.log('Error popup')
            return {
                somfy: 1,
                cherubini: 1,
                ac: 1,
                traffico: 1
            }
        }
    }

    async getAutCf() {
        let res = await api.post('/getAutomationCf.php')
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

    //Get user's saved orders
    async getSavedOrders(email: string){
        console.log('Email', email)
        let res = await api.post('/getSavedOrders.php', {email})
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

    //Saves new order
    async saveOrder(email: string, data: DefaultValues<FormValues>, status: string, price?: number){

        let request = {
            formData: data,
            email: email,
            status: status,
            price: price
        }
        let res = await api.post('/saveOrder.php', {...request})
        if (res.data !== 'Ошибка, заказ не был сохранен.') {
            console.log(res)
            return true
        } else {
            console.log(res)
            return false
        }
    }

    //Delete saved order
    async deleteOrder(id:number){

        let request = {
            id: id
        }

        let res = await api.post('/deleteSavedOrder.php', {...request})
        if (res.data.success) {
            console.log(res)
            return true
        } else {
            console.log(res)
            return false
        }
    }

    //Get user's previous orders
    async getArticles(){
        let res = await api.post('/getArticles.php')
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

}