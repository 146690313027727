import React, { useState} from "react";
import LeftMenu from "../parts/left-menu/left-menu";
import AdminCompleteForm from "../parts/form-elements/admin-complete-form";
import AdminAddForm from "../parts/form-elements/admin-add-form";
import AdminUpdateContainer from "../parts/form-elements/admin-update-container";
import AdminChangeArticle from "../parts/form-elements/admin-change-article";
import AdminStatusUpdateForm from "../parts/form-elements/admin-status-update-form";
import AdminDateUpdateForm from "../parts/form-elements/admin-date-update-form";
import AdminAllUsers from "./AdminAllUsers";
import AdminChangeAutCf from "../parts/form-elements/admin-change-aut-cf";
import AdminChangeModelCf from "../parts/form-elements/admin-change-model-cf";
import AdminChangeAutomatic from "../parts/form-elements/admin-change-automatic";

type props = {
    callPopup: (message: string, type: string) => void,
    role: string
}

const AdminPanel = ({callPopup, role}: props) => {

    const [shownData, setShownData] = useState<string>('')

    const menuRedirection = (data: string) => {
        setShownData(data)
    }

    const dataChoice = (data:string) => {
        switch (data) {
            case 'Добавить клиента': {
                return <AdminAddForm callPopup={callPopup} formType="add"/>
            }
            case 'Редактировать данные': {
                return <AdminUpdateContainer callPopup={callPopup} />
            }
            case 'Обработать заказ': {
                return <AdminCompleteForm />
            }
            case 'Изменить артикула': {
                return <AdminChangeArticle callPopup={callPopup} />
            }
            case 'Изменить коэф. по автоматике': {
                return <AdminChangeAutCf callPopup={callPopup} />
            }
            case 'Изменить коэф. по типу изделия': {
                return <AdminChangeModelCf callPopup={callPopup} />
            }
            case 'Изменить Автоматику': {
                return <AdminChangeAutomatic callPopup={callPopup} />
            }
            case 'Изменить статус заказа': {
                return <AdminStatusUpdateForm callPopup={callPopup} />
            }
            case 'Изменить дату в заказе': {
                return <AdminDateUpdateForm callPopup={callPopup} />
            }
            case 'Все пользователи': {
                return <AdminAllUsers callPopup={callPopup} />
            }

            default: {
                return true
            }
        }
    }

    const menuList =
        role === 'admin' ? {
            menuItems: ['Добавить клиента', 'Редактировать данные', 'Изменить артикула', 'Изменить коэф. по автоматике', 'Изменить коэф. по типу изделия', 'Изменить Автоматику', 'Изменить статус заказа', 'Изменить дату в заказе', 'Все пользователи'],
            menuRedirection: menuRedirection
        }
        : {
            menuItems: ['Добавить клиента', 'Редактировать данные', 'Изменить артикула', 'Изменить статус заказа', 'Изменить дату в заказе', 'Все пользователи'],
            menuRedirection: menuRedirection
        }

    return(
        <div className="grid grid-cols-12 my-12 mx-10">
            <div className="mt-10 col-span-2">
                <LeftMenu {...menuList} />
                <div
                    onClick={() => {
                        menuRedirection('Обработать заказ')
                    }}
                    className='flex justify-center mt-10 bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-3 px-4 rounded'>
                    Обработать заказ
                </div>
            </div>
            <div className="col-span-10 mx-8">
                <div>
                    {dataChoice(shownData)}
                </div>
            </div>
        </div>
    )
}

export default AdminPanel