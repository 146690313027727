import {AdditionalItem, AutCf, DealerDiscounts, FormValues, Item, ItemData, UserData} from "../types/types";
import axios from "axios";
import React from "react";
import SaveSystemService from "./save-system-service";

export default class CalculatorService {

    NDS: {
        sub: number,
        dealer: number
    }
    userData: UserData
    // for each producer
    autCf: AutCf
    automatica: string[]

    colored: boolean

    constructor(userData: UserData, autCf: AutCf, automatica: string[]) {
        this.userData = userData
        this.NDS = this.setNDS(userData.country, userData.type, userData.role)
        this.colored = false
        this.autCf = autCf
        this.automatica = automatica
    }

    static async create(userData: UserData) {
        const SaveSystem = new SaveSystemService()
        const autCf = await SaveSystem.getAutomationCf()
        const automatica = await SaveSystem.getAutomatica()
        const modelCf = await SaveSystem.getModelCf()
        console.log('Automtica: ', automatica)
        return new CalculatorService(userData, autCf, automatica)
    }

     GetRecomendedPriceMultipler (model: string) {
        switch (model) {
            case 'CLASSIC': {
                return {
                    model: 1.25,
                    automation: 1.05
                }
            }
            case 'Тент на люверсах': {
                return {
                    model: 1.25,
                    automation: 1
                }
            }
            case 'MODERN': {
                return {
                    model: 1.2,
                    automation: 1
                }
            }
            case 'Optima': {
                return {
                    model: 1.25,
                    automation: 1.15
                }
            }
            case 'VIKTORY': case 'Гармония': {
                return {
                    model: 1.25,
                    automation: 1.15
                }
            }
            case 'Optima PLUS': case 'Helix': case 'Domea':{
                return {
                    model: 1.25,
                    automation: 1.2075
                }
            }
            case 'ITALIA': {
                return {
                    model: 1.27,
                    automation: 1.15
                }
            }
            case 'ASN 130 GPZ': {
                return {
                    model: 1.48,
                    automation: 1.2075
                }
            }
            case 'ASN 110 cab': {
                return {
                    model: 1.48,
                    automation: 1.15
                }
            }
            case 'ASN 130 GPZ TENS':{
                return {
                    model: 1.2,
                    automation: 1.15
                }
            }
            case 'ASN 130 Эконом':{
                return {
                    model: 1.4,
                    automation: 1.15
                }
            }
            case 'Traffico': {
                return {
                    model: 1.4,
                    automation: 1.15
                }
            }
            default: {
                return {
                    model: 1,
                    automation: 1
                }
            }
        }
    }


    async countPriceOfItem(item: ItemData, dealerDiscounts?: DealerDiscounts, isRecomended: boolean = false) {
        let priceTable: number[][]
        let standartPrice: number
        let colorPrice: number
        let factoryPrice: number
        let electricDriverPrice: number
        let finalPrice: number
        let heightIndex: number
        let widthIndex: number
        let discount: number
        let autDiscount: number
        // for each model
        const SaveSystem = new SaveSystemService()
        const cf = await SaveSystem.getModelCf()

        if (isRecomended) {
            discount = 0
            autDiscount = 0
        } else if (dealerDiscounts) {
            discount = dealerDiscounts.discount
            autDiscount = dealerDiscounts.autDiscount
        } else {
            discount = this.userData.discount
            autDiscount = this.userData.autDiscount
        }

        const RecPriceCof = isRecomended ? this.GetRecomendedPriceMultipler(item.model) : {model: 1, automation: 1}

        switch (item.model) {
            case 'Выставочный стенд': {
                let finalPrice = (101 * (1 - autDiscount/100))*RecPriceCof.automation
                if(dealerDiscounts){
                    finalPrice = Math.ceil((finalPrice/this.NDS.dealer)*100)/100
                } else {
                    finalPrice = Math.ceil((finalPrice/this.NDS.sub)*100)/100
                }
                return finalPrice
            }
            case 'Traffico': {
                priceTable = [
                    [3421,3702,3981,4504,4791,5068,5353,5625,5913,6187,6931,7218,7506,7816,8104,8391,8702,9006,9395,9726,10144],
                    [3672,3981,4292,4857,5061,5475,5793,6095,6413,6713,7497,7814,8130,8471,8788,9102,9413,9742,10083,10436,10801],
                    [3932,4279,4620,5225,5577,5907,6261,6591,6943,7274,8095,8440,8787,9165,9509,9855,10232,10621,11025,11444,11879],
                    [4188,4558,4929,5577,5958,6319,6700,7061,7442,7802,8661,9036,9411,9817,10193,10567,10974,11391,11824,12273,12739],
                    [4452,4856,5258,5945,6362,6752,7167,7556,7973,8363,9259,9662,10067,10511,10915,11320,11763,12221,12698,13193,13708],
                    [4742,5171,5600,6331,6776,7191,7633,8048,8491,8907,9861,10290,10721,11194,11625,12055,12527,12966,13419,13889,14375],
                    [5050,5507,5964,6743,7216,7658,8129,8571,9043,9486,10501,10959,11418,11921,12380,12839,13341,13808,14292,14792,15309]
                ]

                heightIndex = Math.ceil(item.height / 500) - 6
                widthIndex = Math.ceil(item.width / 500) - 6
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.traffico.constr)
                break;
            }
            case 'CLASSIC': {
                priceTable = [
                    [251, 285, 298, 333, 351, 362, 433, 446, 491, 521, 556, 569],
                    [268, 303, 316, 351, 369, 415, 462, 475, 510, 545, 581, 592],
                    [333, 347, 362,	399, 418, 467, 521,	571, 585, 618, 656,	669],
                    [352, 365, 404,	417, 438, 486, 537,	585, 603, 672, 687,	701],
                    [357, 371, 420,	434, 488, 502, 591,	605, 620, 688, 702,	751],
                    [373, 421, 436,	450, 519, 534, 618,	632, 682, 716, 730,	778],
                    [395, 443, 459,	518, 538, 552, 640,	654, 704, 738, 798,	813],
                    [441, 490, 508,	570, 592, 635, 693,	710, 780, 818, 870,	886],
                    [499, 514, 531,	592, 615, 677, 736,	787, 804, 844, 907,	923],
                    [521, 537, 601,	616, 684, 700, 759,	822, 838, 924, 941,	1004]
                ]

                heightIndex = Math.ceil(item.height / 100) - 9
                widthIndex = Math.ceil(item.width / 500) - 3
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.classic.constr)
                break;
            }
            case 'MODERN': {
                priceTable = [
                    [495, 558, 580, 666, 706, 709],
                    [518, 581, 604, 689, 709, 793],
                    [603, 678, 704, 793, 817, 905],
                    [684, 707, 776, 823, 846, 935],
                    [724, 748, 837, 884, 971, 996],
                    [753, 841, 867, 914, 1022, 1047],
                    [815, 902, 929, 953, 1083, 1109],
                    [888, 979, 1011, 1143, 1172, 1250],
                    [1015, 1043, 1074, 1206, 1236, 1347],
                    [1045, 1074, 884, 1238, 1350, 1378]
                ]

                heightIndex = Math.ceil(item.height / 100) - 9
                widthIndex = Math.ceil(item.width / 500) - 3
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.modern.constr)
                break;
            }
            case 'Optima': {
                priceTable = [
                    [762, 1132, 1139, 1171, 1260, 1286, 1478, 1514, 1665, 1792, 2361, 2387, 2461, 2579, 2600, 2607, 3008],
                    [857, 1174, 1191, 1214, 1315, 1344, 1548, 1571, 1742, 1871, 2450, 2477, 2623, 2741, 2700, 2710, 3198],
                    [924, 1221, 1239, 1262, 1371, 1399, 1632, 1655, 1838, 2023, 2573, 2602, 2734, 2855, 2930, 2943, 3264],
                    [986, 1266, 1283, 1309, 1478, 1508, 1710, 1734, 1999, 2187, 2714, 2744, 2831, 2953, 3013, 3027, 3470],
                    [1197, 1477, 1413, 1441, 1611, 1645, 1855, 1881, 2122, 2334, 3024, 3057, 3107, 3232, 3296, 3314, 3877],
                    [1337, 1616, 1541, 1572, 1750, 1778, 2050, 2079, 2350, 2594, 3053, 3086, 3397, 3525, 3642, 3667, 4277]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.optima.constr)
                break;
            }
            case 'Optima PLUS': {
                priceTable = [
                    [496, 695, 700, 728, 752, 768, 888, 1029, 1199, 1259, 1577, 1640, 1707, 1993, 2072, 2155, 2248],
                    [558, 722, 733, 757, 787, 804, 932, 1100, 1287, 1363, 1688, 1772, 1844, 2113, 2196, 2279, 2433],
                    [603, 753, 764, 779, 821, 839, 972, 1156, 1353, 1447, 1805, 1931, 2017, 2249, 2351, 2444, 2619],
                    [644, 781, 793, 831, 888, 907, 1033, 1238, 1461, 1548, 1923, 2056, 2138, 2362, 2479, 2578, 2750]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.optima_plus.constr)
                break;
            }

            case 'VIKTORY': {
                priceTable = [
                    [4219, 4347, 5713, 6071, 6964, 7907, 8388, 9666],
                    [4360, 4481, 5898, 6272, 7203, 8175, 8668, 9769],
                    [0, 4645, 6114, 6515, 7354, 8490, 9000, 10111],
                    [0, 4792, 6265, 0, 0, 0, 0, 0]
                ]
                if (item.height !== 5650){
                    heightIndex = Math.ceil((item.height - 100) / 500) - 8
                } else {
                    heightIndex = 3
                }
                widthIndex = Math.ceil(item.width / 1000) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.viktory.constr)
                break;
            }

            case 'Helix': {
                priceTable = [
                    [494, 701, 706],
                    [559, 729, 741],
                    [605, 761, 773],
                    [641, 782, 794, 822, 908],
                    [1020, 1155, 1203, 1216, 1277]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex] * cf.helix.constr
                break;
            }

            case 'Гармония': {
                priceTable = [
                    [2165, 2509, 2621, 2914, 3020, 3358, 4052, 4393, 4734, 5077, 5419, 5760],
                    [2201, 2537, 2652, 2944, 3054, 3391, 4108, 4450, 4790, 5131, 5474, 5816],
                    [2238, 2563, 2683, 2974, 3085, 3422, 4161, 4502, 4845, 5186, 5528, 5868],
                    [2373, 2610, 2738, 3029, 3149, 3486, 4256, 4598, 4939, 5280, 5623, 5964],
                    [0, 2656, 2792, 3085, 3212, 3551, 4350, 4693, 5034, 5375, 5718, 6059],
                    [0, 0, 2897, 3191, 3337, 3675, 4906, 4866, 5207, 5550, 5890, 6233],
                    [0, 0, 0, 3523, 3687, 4026, 5153, 5596, 6038, 6481, 6926, 7367],
                    [0, 0, 0, 0, 3844, 4181, 5387, 5831, 6241, 6716, 7161, 7602],
                    [0, 0, 0, 0, 0, 4334, 0, 6069, 6512, 7524, 8536, 9548]
                ]

                switch(true) {
                    case item.width <= 3000 : {
                        widthIndex = 0
                        break;
                    }
                    case item.width <= 3600 : {
                        widthIndex = 1
                        break;
                    }
                    case item.width <= 4000 : {
                        widthIndex = 2
                        break;
                    }
                    case item.width <= 4800 : {
                        widthIndex = 3
                        break;
                    }
                    case item.width <= 5000 : {
                        widthIndex = 4
                        break;
                    }
                    default : {
                        widthIndex = Math.ceil((item.width) / 1000) - 1
                        break;
                    }
                }

                switch (true) {
                    case item.height <= 1600 : {
                        heightIndex = 0
                        break;
                    }
                    case item.height <= 1850 : {
                        heightIndex = 1
                        break;
                    }
                    case item.height <= 2100 : {
                        heightIndex = 2
                        break;
                    }
                    case item.height <= 2350 : {
                        heightIndex = 3
                        break;
                    }
                    case item.height <= 2600 : {
                        heightIndex = 4
                        break;
                    }
                    case item.height <= 3100 : {
                        heightIndex = 5
                        break;
                    }
                    case item.height <= 3600 : {
                        heightIndex = 6
                        break;
                    }
                    case item.height <= 4100 : {
                        heightIndex = 7
                        break;
                    }
                    case item.height <= 4350 : {
                        heightIndex = 8
                        break;
                    }
                    default: {
                        heightIndex = 8
                        break;
                    }
                }
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                console.log(heightIndex,widthIndex, item.width, item.height)
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.garmonia.constr)
                break;
            }

            case 'Domea': {
                priceTable = [
                    [1347, 1351, 1355, 1358, 1361, 1365, 1368, 1372, 1375, 1378, 1382, 1385, 1388, 1392, 1395, 1398, 1402, 1405, 1410, 1413, 1416, 1653, 1656, 1660, 1663, 1667, 1670, 1673, 1677, 1680, 1684, 2020],
                    [0, 0, 1358, 1363, 1366, 1370, 1374, 1378, 1382, 1386, 1391, 1394, 1398, 1402, 1406, 1410, 1414, 1418, 1422, 1425, 1430, 1662, 1667, 1670, 1674, 1678, 1682, 1686, 1690, 1693, 1698, 2037],
                    [0, 0, 0, 0, 0, 1373, 1377, 1382, 1386, 1391, 1395, 1400, 1404, 1409, 1413, 1418, 1422, 1427, 1431, 1435, 1440, 1670, 1674, 1679, 1683, 1688, 1692, 1697, 1701, 1706, 1710, 2062],
                    [0,	0, 0, 0, 0, 0, 0, 1404, 1410, 1414, 1419, 1424, 1429, 1434, 1439, 1444, 1449, 1455, 1459, 1465, 1469, 1692, 1697, 1702, 1707, 1712, 1717, 1723, 1727, 1733, 1737, 2088],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 1422, 1428, 1432, 1438, 1443, 1449, 1455, 1460, 1466, 1471, 1477, 1697, 1702, 1708, 1714, 1719, 1725, 1730, 1736, 1741, 1746, 2141],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1441, 1447, 1452, 1459, 1465, 1471, 1477, 1484, 1489, 1702, 1708, 1715, 1720, 1727, 1733, 1739, 1745, 1752, 1757, 2205],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1476, 1483, 1489, 1496, 1503, 1510, 1717, 1724, 1730, 1737, 1744, 1751, 1757, 1764, 1771, 1778, 2311]
                    ]

                heightIndex = Math.ceil((item.height - 100) / 250) - 6
                widthIndex = Math.ceil(item.width / 100) - 20
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                switch (30 < widthIndex && widthIndex <= 40) {
                    case true: {
                        standartPrice = Math.ceil(priceTable[heightIndex][31] * cf.domea.constr)
                        break;
                    }
                    default: {
                        standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.domea.constr)
                        break;
                    }
                }
                break;
            }

            case 'ITALIA': {
                priceTable = [
                    [201, 249, 263,	312, 336, 374, 522],
                    [208, 262, 277,	328, 353, 395, 536],
                    [212, 272, 284,	341, 364, 410, 563],
                    [220, 282, 296,	356, 381, 431, 575],
                    [229, 295, 309,	373, 397, 451, 606]
                ]

                heightIndex = Math.ceil(item.height / 200) - 4

                switch(true) {
                    case item.width <= 1200 : {
                        widthIndex = 0
                        break;
                    }
                    case item.width <= 2000 : {
                        widthIndex = 1
                        break;
                    }
                    case item.width <= 2400 : {
                        widthIndex = 2
                        break;
                    }
                    case item.width <= 3000 : {
                        widthIndex = 3
                        break;
                    }
                    case item.width <= 3600 : {
                        widthIndex = 4
                        break;
                    }
                    case item.width <= 4000 : {
                        widthIndex = 5
                        break;
                    }
                    case item.width <= 5000 : {
                        widthIndex = 6
                        break;
                    }
                    default : {
                        widthIndex = 6
                        break;
                    }
                }
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.italia.constr)
                break;
            }

            case 'ASN 130 GPZ': {
                priceTable = [//height X width = priceEuro
                    [144, 177, 191, 197, 221, 221, 234, 236, 255, 275, 307, 311, 331, 341, 360, 368, 386, 405, 423, 442, 460],
                    [148, 178, 192, 203, 229, 247, 262, 269, 292, 314, 334, 346, 368, 379, 392, 392, 412, 432, 451, 471, 490],
                    [152, 182, 197, 206, 232, 250, 263, 287, 311, 334, 341, 371, 387, 409, 424, 428, 449, 470, 492, 513, 535],
                    [154, 185, 200, 219, 246, 253, 278, 303, 329, 354, 372, 396, 413, 437, 451, 475, 499, 523, 546, 570, 594],
                    [162, 194, 210, 229, 258, 278, 306, 324, 351, 370, 392, 418, 440, 460, 465, 495, 520, 544, 569, 594, 619],
                    [165, 196, 212, 243, 273, 295, 324, 343, 371, 392, 411, 434, 456, 472, 476, 507, 532, 557, 583, 608, 633],
                    [175, 210, 228, 260, 293, 316, 348, 368, 398, 416, 422, 450, 473, 489, 492, 518, 544, 570, 596, 622, 648],
                    [187, 224, 243, 278, 312, 337, 371, 392, 416, 420, 429, 469, 492, 515, 525, 552, 580, 608, 635, 663, 691],
                    [194, 233, 252, 288, 324, 350, 385, 407, 441, 446, 451, 493, 518, 535, 545, 574, 603, 631, 660, 689, 717],
                    [209, 251, 272, 311, 350, 358, 394, 439, 466, 481, 509, 525, 564, 597, 602, 626, 657, 689, 720, 751, 783],
                    [220, 264, 286, 327, 368, 377, 414, 462, 500, 522, 535, 565, 600, 635, 648, 658, 683, 716, 748, 781, 813],
                    [228, 274, 296, 339, 370, 391, 430, 479, 519, 553, 567, 599, 636, 674, 672, 691, 717, 751, 785, 819, 853],
                    [241, 289, 313, 358, 391, 412, 454, 505, 547, 577, 593, 618, 649, 688, 709, 729, 757, 793, 829, 865, 901],
                    [258, 310, 339, 387, 411, 434, 477, 532, 558, 601, 637, 651, 691, 699, 712, 740, 748, 764, 809, 822, 857],
                    [271, 325, 356, 407, 432, 456, 501, 547, 586, 625, 655, 699, 726, 734, 747, 777, 786, 802, 850, 864, 900],
                    [284, 341, 373, 426, 452, 477, 525, 555, 595, 633, 671, 708, 752, 769, 783, 814, 823, 840, 890, 905, 942],
                    [297, 356, 390, 446, 473, 478, 526, 561, 608, 655, 701, 748, 795, 804, 819, 851],
                    [310, 372, 407, 465, 484, 488, 537, 586, 634, 660, 707, 754 ,802, 819],
                    [323, 387, 424, 484, 493, 508, 559, 610, 661, 688, 720, 768]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.asn130.constr)
                break;
            }
            case 'ASN 130 Эконом': {
                priceTable = [//height X width = priceEuro
                    [198,208,218,228,241,248,257,267,277,290,303,312,322,332,345,352,369],
                    [209,219,229,239,250,253,263,273,283,303,314,323,333,343,360,363,380],
                    [220,230,240,250,256,259,268,278,295,311,330,347,356,369,376,384,396],
                    [231,241,253,266,282,286,296,308,321,339,347,356,366,380,393,396,418],
                    [239,249,259,268,281,295,305,315,328,347,358,367,377,391,409,413,437],
                    [248,257,267,277,294,305,315,325,342,361,374,388,402,415,427,431,465],
                    [259,268,278,288,297,314,323,337,354,375,388,398,411,429,447,471,476],
                    [270,279,293,303,311,325,334,348,367,388,402,411,425,444,461,488,484],
                    [281,290,300,310,315,336,345,361,383,404,413,427,442,464,485,507,512],
                    [295,305,315,325,329,348,364,387,409,430,439,455,471,494,512,530,545]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                const mountAddition = item.mountType === 'Встроенный' ? 1.07 : 1
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.asn130Eko.constr * mountAddition)
                break;
            }
            case 'ASN 110 cab':{
                priceTable = [//height X width = priceEuro
                    [83, 100, 117, 120, 135, 139, 147, 148, 161, 173, 175, 178, 189, 200, 211, 223],
                    [84, 101, 118, 124, 140, 155, 165, 169, 184, 198, 200, 203, 216, 229, 242, 254],
                    [86, 103, 120, 126, 142, 157, 165, 180, 195, 210, 215, 229, 243, 258, 272, 286],
                    [87, 105, 122, 134, 150, 159, 175, 191, 207, 223, 238, 254, 270, 286, 302, 318],
                    [92, 110, 129, 140, 157, 175, 192, 210, 227, 245, 262, 280, 297, 315, 332, 350],
                    [94, 111, 130, 148, 167, 185, 204, 222, 241, 259, 278, 296, 315, 333, 352, 371],
                    [99, 119, 139, 159, 179, 199, 219, 238, 258, 278, 298, 318, 338, 358, 378, 397],
                    [106, 127, 148, 169, 191, 212, 233, 254, 275, 297, 318, 339, 360, 381, 403, 424],
                    [110, 132, 154, 176, 198, 220, 242, 264, 286, 308, 330, 352, 374, 396, 418, 440]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.asn110.constr)
                break;
            }
            case 'ASN 130 GPZ TENS':{
                priceTable = [//height X width = priceEuro
                    [1109,	1177,	1299,	1303,	1369,	1370,	1374,	1380,	1451,	1563,	1742,	1770,	1881,	1937,	2045,	2092,	2196,	2301,	2406,	2510,	2615],
                    [1138,	1183,	1328,	1346,	1413,	1542,	1566,	1577,	1659,	1786,	1901,	1968,	2091,	2152,	2227,	2230,	2341,	2453,	2564,	2676,	2787],
                    [1164,	1210,	1358,	1365,	1434,	1565,	1568,	1678,	1765,	1901,	1940,	2110,	2198,	2327,	2408,	2431,	2552,	2674,	2796,	2917,	3039],
                    [1185,	1233,	1383,	1448,	1521,	1580,	1659,	1776,	1868,	2011,	2112,	2253,	2345,	2483,	2566,	2701,	2836,	2971,	3106,	3241,	3376],
                    [1245,	1294,	1452,	1517,	1593,	1738,	1825,	1953,	1993,	2102,	2228,	2377,	2499,	2617,	2642,	2813,	2954,	3094,	3235,	3376,	3516],
                    [1268,	1306,	1465,	1607,	1688,	1842,	1934,	2069,	2111,	2227,	2336,	2465,	2590,	2682,	2704,	2880,	3024,	3168,	3312,	3456,	3600],
                    [1347,	1401,	1571,	1724,	1810,	1975,	2074,	2220,	2265,	2363,	2397,	2557,	2687,	2780,	2798,	2945,	3092,	3240,	3387,	3534,	3682],
                    [1359,	1413,	1582,	1731,	1827,	1991,	2169,	2321,	2365,	2386,	2441,	2665,	2799,	2929,	2983,	3140,	3297,	3454,	3611,	3768,	3925],
                    [1373,	1420,	1601,	1735,	1844,	2089,	2188,	2435,	2508,	2534,	2566,	2801,	2942,	3044,	3099,	3262,	3426,	3589,	3752,	3915,	4078],
                    [1479,	1492,	1621,	1767,	1987,	2144,	2358,	2496,	2648,	2731,	2894,	2984,	3207,	3396,	3422,	3559,	3737,	3915,	4093,	4271,	4449],
                    [1499,	1569,	1626,	1858,	2090,	2255,	2480,	2624,	2843,	2967,	3044,	3210,	3411,	3612,	3684,	3742,	3882,	4067,	4252,	4437,	4622],
                    [1519,	1627,	1685,	1926,	2167,	2337,	2571,	2721,	2947,	3141,	3226,	3403,	3616,	3829,	3819,	3927,	4074,	4268,	4462,	4656,	4850],
                    [1530,	1658,	1779,	2033,	2287,	2467,	2714,	2872,	3111,	3281,	3368,	3513,	3691,	3908,	4031,	4145,	4300,	4505,	4710,	4915,	5120],
                    [1532,	1714,	1927,	2202,	2337,	2493,	2743,	3023,	3174,	3418,	3623,	3698,	3929,	3974,	4046,	4207,	4254,	4342,	4599,	4675,	4870],
                    [1541,	1849,	2023,	2312,	2454,	2591,	2850,	3109,	3332,	3551,	3722,	3970,	4126,	4172,	4249,	4418,	4467,	4559,	4829,	4909,	5113],
                    [1614,	1937,	2120,	2423,	2571,	2714,	2985,	3154,	3380,	3600,	3814,	4022,	4274,	4371,	4451,	4628,	4679,	4777,	5059,	5142,	5357],
                    [1687,	2025,	2216,	2533,	2688,	2718,	2990,	3190,	3456,	3721,	3987,	4253,	4519,	4570,	4653,	4838,	4892,	4994,	5289,	5376,	5600],
                    [1761,	2113,	2312,	2643,	2749,	2774,	3051,	3328,	3606,	3752,	4020,	4288,	4556,	4656,	4737,	4799,	4843,	5005,	5233,	5460,	5610],
                    [1834,	2201,	2409,	2753,	2805,	2889,	3178,	3467,	3756,	3909,	4090,	4363,	4636,	4733,	4935,	4999,	5045,	5214,	5451,	5688,	5843],
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = Math.ceil(priceTable[heightIndex][widthIndex] * cf.tenz.constr)
                break;
            }
            case 'Тент на люверсах':{
                let S = (item.height * item.width)/1000000
                let P = ((item.height + item.width)/1000)*2
                let N = Math.ceil((P/0.4)*4)
                const priceRise = 1.15
                standartPrice = (S*25+14+N)*priceRise
                break;
            }
            default: {
                standartPrice = 0
            }
        }
        switch (item.factory) {
            case 'Crystal': {
               // if (item.model !== 'Тент на люверсах' ) {
                    factoryPrice = Math.ceil((standartPrice / 100) * 10)
                //} else {
                    //factoryPrice = 0
                //}
                break;
            }
            case 'COPACO': {
                factoryPrice = Math.ceil((standartPrice / 100) * 7)
                break;
            }
            case 'Dickson акриловые ткани': {
                const list = await this.getListOfArticles('Dickson акриловые ткани')

                if (list.includes(item.article)){
                    factoryPrice = 0
                } else {
                    factoryPrice = 300
                }
                break;
            }
            case 'Dickson ПВХ': {
                factoryPrice = 0
                break;
            }
            default: {
                factoryPrice = 0
            }
        }

        switch (item.model) {
            case 'Тент на люверсах':
                colorPrice = 0
                break;
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': case 'ASN 110 cab':
                if (item.color === 'Ral 9010(белый)') {
                    colorPrice = 0
                } else {
                    colorPrice = Math.ceil((standartPrice / 100) * 10)
                    this.colored = true
                }
                break
            case 'ASN 130 Эконом':
                switch (item.color) {
                    case 'Ral 9016(белый)':case 'Ral 8017(коричневый)':case 'Ral 7024(антрацит)':
                        colorPrice = 0
                        break
                    default:
                        colorPrice = Math.ceil((standartPrice / 100) * 10)
                        this.colored = true
                }
                break
            case 'ASN 130 GPZ':
                switch (item.color) {
                    case 'Ral 9016(белый)': case 'Ral 9004(черный)': case 'Ral 8017(коричневый)':case 'Ral 1024(бежевый)':case 'Ral 7024(антрацит)':case 'Ral 1013(жемчужный)':
                        colorPrice = 0
                        break
                    default:
                        colorPrice = Math.ceil((standartPrice / 100) * 10)
                        this.colored = true
                }
                break
            default: {
                switch (item.color) {
                    case 'Ral 9010(белый)': case 'Ral 9004(черный)': case 'Ral 8017(коричневый)':case 'Ral 1024(бежевый)':case 'Ral 7024(антрацит)':case 'Ral 1013(жемчужный)':
                        colorPrice = 0
                        break
                    default:
                        colorPrice = Math.ceil((standartPrice / 100) * 10)
                        this.colored = true
                }
            }

        }

        switch (this.electricDriverSelection(item.width, item.height, item.controlsFactory, item.automaticFactory, item.control, item.controlAcceptor, item.model)) {
            case 'электропривод V220': {
                electricDriverPrice = 714 * this.autCf.traffico
                break;
            }
            case this.automatica[0]: {
                electricDriverPrice = 55 * this.autCf.ac
                break;
            }
            case this.automatica[1]: {
                electricDriverPrice = 48 * this.autCf.ac
                break;
            }
            case this.automatica[2]: {
                electricDriverPrice = 46 * this.autCf.ac
                break;
            }
            case this.automatica[3]: {
                electricDriverPrice = 48 * this.autCf.ac
                break;
            }
            case this.automatica[4]: {
                electricDriverPrice = 56 * this.autCf.ac
                break;
            }
            case this.automatica[5]: {
                electricDriverPrice = 63 * this.autCf.ac
                break;
            }
            case this.automatica[6]: {
                electricDriverPrice = 35 * this.autCf.cherubini
                break;
            }
            case this.automatica[7]: {
                electricDriverPrice = 38 * this.autCf.cherubini
                break;
            }
            case this.automatica[8]: {
                electricDriverPrice = 44 * this.autCf.cherubini
                break;
            }
            case this.automatica[9]: {
                electricDriverPrice = 55 * this.autCf.cherubini
                break;
            }
            case this.automatica[10]: {
                electricDriverPrice = 146 * this.autCf.cherubini
                break;
            }
            case this.automatica[11]: {
                electricDriverPrice = 160 * this.autCf.cherubini
                break;
            }
            case this.automatica[12]: {
                electricDriverPrice = 260 * this.autCf.cherubini
                break;
            }
            case this.automatica[13]: {
                electricDriverPrice = 280 * this.autCf.cherubini
                break;
            }
            case this.automatica[14]: {
                electricDriverPrice = 320 * this.autCf.cherubini
                break;
            }
            case this.automatica[15]: {
                electricDriverPrice = 155 * this.autCf.cherubini
                break;
            }
            case this.automatica[16]: {
                electricDriverPrice = 228 * this.autCf.cherubini
                break;
            }
            case this.automatica[17]: {
                electricDriverPrice = 264 * this.autCf.cherubini
                break;
            }
            case this.automatica[18]: {
                electricDriverPrice = 312 * this.autCf.cherubini
                break;
            }
            case this.automatica[19]: {
                electricDriverPrice = 340 * this.autCf.cherubini
                break;
            }
            case this.automatica[20]: {
                electricDriverPrice = 385 * this.autCf.cherubini
                break;
            }
            case this.automatica[21]: {
                electricDriverPrice = 52 * this.autCf.somfy
                break;
            }
            case this.automatica[22]: {
                electricDriverPrice = 63 * this.autCf.somfy
                break;
            }
            case this.automatica[23]: {
                electricDriverPrice = 175 * this.autCf.somfy
                break;
            }
            case this.automatica[24]: {
                electricDriverPrice = 196 * this.autCf.somfy
                break;
            }
            case 'ручное управление': {
                switch (item.model){
                    case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': {
                        electricDriverPrice = 51
                        break
                    }
                    default: {
                        electricDriverPrice = 0
                        break
                    }
                }
                break
            }
            case 'Без управления': {
                electricDriverPrice = 0
                break;
            }
            case this.automatica[25]: {
                electricDriverPrice = 244 * this.autCf.somfy
                break;
            }
            case this.automatica[26]: {
                electricDriverPrice = 265 * this.autCf.somfy
                break;
            }
            case this.automatica[27]: {
                electricDriverPrice = 237 * this.autCf.somfy
                break;
            }
            case this.automatica[28]: {
                electricDriverPrice = 270  * this.autCf.somfy
                break;
            }
            case this.automatica[29]: {
                electricDriverPrice = 287 * this.autCf.somfy
                break;
            }
            case this.automatica[30]: {
                electricDriverPrice = 303 * this.autCf.somfy
                break;
            }
            case this.automatica[31]: {
                electricDriverPrice = 375 * this.autCf.somfy
                break;
            }
            case this.automatica[32]: {
                electricDriverPrice = 426 * this.autCf.somfy
                break;
            }
            case 'Нет привода под заданые размеры!': {
                electricDriverPrice = 0
                break;
            }
            default: {
                electricDriverPrice = 0
            }
        }
        const constructionPrice = ((standartPrice + colorPrice + factoryPrice)*(1 - discount/100))*RecPriceCof.model
        const automationPrice = (electricDriverPrice * (1 - autDiscount/100))*RecPriceCof.automation
        finalPrice = constructionPrice + automationPrice;
        if(dealerDiscounts){
            finalPrice = Math.ceil((finalPrice/this.NDS.dealer)*100)/100
        } else {
            finalPrice = Math.ceil((finalPrice/this.NDS.sub)*100)/100
        }
        return finalPrice

    }
    setNDS(country: string, type: string, role: string) {
        if(role === 'Subdealer' && country === 'Казахстан'){
            return {
                sub: 1.07,
                dealer: 1.2
            }
        }else if (country === 'Беларусь') {
            return {
                sub: 1,
                dealer: 1
            }
        } else if (type === 'Физическое лицо' || role === 'Subdealer') {
            return {
                sub: 1,
                dealer: 1.2
            }
        }else {
            return {
                sub: 1.2,
                dealer: 1.2
            }
        }
    }

    electricDriverSelection(width: number, height: number, factory: string, automaticFactory: string, type: string, somfyAcceptor: string, model: string): string {
        console.log({
            width, height, factory, automaticFactory, type, somfyAcceptor, model
        })
        if (type === 'Автоматическое') {
            switch (model) {
                case 'Helix': case 'ITALIA': {
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (width <= 4100) {
                            return this.automatica[15]
                        } else if (width <= 6000 && width > 4100) {
                            return this.automatica[16]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (
                        factory === 'Somfy'
                    ) {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 4100) {
                                return this.automatica[15]
                            } else if (width <= 6000 && width > 4100) {
                                return this.automatica[16]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[10]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[10]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[11]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break;
                }
                case 'Optima PLUS': {
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (width <= 8000) {
                            return this.automatica[15]
                        } else if (width <= 10000 && width > 8000) {
                            return this.automatica[16]
                        } else if (width <= 14000 && width > 10000) {
                            return this.automatica[17]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (
                        factory === 'Somfy'
                    ) {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[15]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[16]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[17]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[10]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[10]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[11]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break;
                }
                case 'Гармония': case 'Domea': {
                    if (factory === 'Cherubini') {
                        if (width <= 8000) {
                            return this.automatica[14]
                        } else if (width <= 10000 && width > 8000) {
                            return this.automatica[19]
                        } else if (width <= 14000 && width > 10000) {
                            return this.automatica[20]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (
                        factory === 'Somfy'
                    ) {
                        if (somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break
                }
                case 'Optima': {
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (width <= 8000) {
                            return this.automatica[15]
                        } else if (width <= 10000 && width > 8000) {
                            return this.automatica[16]
                        } else if (width <= 14000 && width > 10000) {
                            return this.automatica[18]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (
                        factory === 'Somfy'
                    ) {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (automaticFactory === 'Cherubini') {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[15]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[16]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[18]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break;
                }
                case 'VIKTORY': {
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (width <= 8000) {
                            return this.automatica[16]
                        } else if (width <= 10000 && width > 8000) {
                            return this.automatica[17]
                        } else if (width <= 14000 && width > 10000) {
                            return this.automatica[18]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (
                        factory === 'Somfy'
                    ) {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (width <= 8000) {
                                return this.automatica[27]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[28]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[29]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if(somfyAcceptor === undefined) {
                            somfyAcceptor = '-'
                        }
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (width <= 8000) {
                                return this.automatica[16]
                            } else if (width <= 10000 && width > 8000) {
                                return this.automatica[17]
                            } else if (width <= 14000 && width > 10000) {
                                return this.automatica[18]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break;
                }
                case 'ASN 130 GPZ': {
                    const volume = height * width
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (volume <= 5000000) {
                            return this.automatica[2]
                        } else if (volume <= 8000000 && volume > 5000000) {
                            return this.automatica[2]
                        } else if (volume <= 18000000 && volume > 8000000) {
                            return this.automatica[1]
                        } else if (volume <= 25000000 && volume > 18000000) {
                            return this.automatica[0]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (factory === 'Somfy') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 7500000) {
                                return this.automatica[21]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[22]
                            } else if (volume <= 25000000 && volume > 12000000) {
                                return this.automatica[23]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 12000000) {
                                return this.automatica[24]
                            } else if (volume <= 20000000 && volume > 12000000) {
                                return this.automatica[25]
                            } else if (volume <= 25000000 && volume > 20000000){
                                return this.automatica[26]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 5000000) {
                                return this.automatica[3]
                            } else if (volume <= 8000000 && volume > 5000000) {
                                return this.automatica[4]
                            } else if (volume <= 18000000 && volume > 8000000) {
                                return this.automatica[4]
                            } else if (volume <= 25000000 && volume > 18000000) {
                                return this.automatica[5]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break
                }
                case 'ASN 110 cab': {
                    const volume = height * width
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (volume <= 7500000) {
                            return this.automatica[2]
                        } else if (volume <= 12000000 && volume > 7500000) {
                            return this.automatica[2]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (factory === 'Somfy') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 7500000) {
                                return this.automatica[21]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[22]
                            } else if (volume <= 25000000 && volume > 12000000) {
                                return this.automatica[23]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 12000000) {
                                return this.automatica[24]
                            } else if (volume <= 20000000 && volume > 12000000) {
                                return this.automatica[25]
                            } else if (volume <= 25000000 && volume > 20000000){
                                return this.automatica[26]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 7500000) {
                                return this.automatica[3]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[4]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break
                }
                case 'ASN 130 GPZ TENS': {
                    const volume = height * width
                    if (factory === 'Somfy') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 7500000) {
                                return this.automatica[21]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[22]
                            } else if (volume <= 25000000 && volume > 12000000) {
                                return this.automatica[23]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 12000000) {
                                return this.automatica[24]
                            } else if (volume <= 20000000 && volume > 12000000) {
                                return this.automatica[25]
                            } else if (volume <= 25000000 && volume > 20000000){
                                return this.automatica[26]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                         if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 7500000) {
                                return this.automatica[13]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[13]
                            } else if (volume <= 20000000 && volume > 12000000) {
                                return this.automatica[14]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                    break
                }
                default: {
                    const volume = height * width
                    if (
                        factory === 'AC инженеринг'
                    ) {
                        if (volume <= 5000000) {
                            return this.automatica[6]
                        } else if (volume <= 10000000 && volume > 5000000) {
                            return this.automatica[7]
                        } else if (volume <= 20000000 && volume > 10000000) {
                            return this.automatica[8]
                        } else if (volume <= 25000000 && volume > 20000000) {
                            return this.automatica[9]
                        } else {
                            return 'Нет привода под заданые размеры!'
                        }
                    }
                    if (factory === 'Somfy') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 7500000) {
                                return this.automatica[21]
                            } else if (volume <= 12000000 && volume > 7500000) {
                                return this.automatica[22]
                            } else if (volume <= 25000000 && volume > 12000000) {
                                return this.automatica[23]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 12000000) {
                                return this.automatica[24]
                            } else if (volume <= 20000000 && volume > 12000000) {
                                return this.automatica[25]
                            } else if (volume <= 25000000 && volume > 20000000){
                                return this.automatica[26]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    } else if (factory === 'Cherubini') {
                        if (somfyAcceptor === '-' || somfyAcceptor === undefined) {
                            if (volume <= 5000000) {
                                return this.automatica[6]
                            } else if (volume <= 10000000 && volume > 5000000) {
                                return this.automatica[7]
                            } else if (volume <= 20000000 && volume > 10000000) {
                                return this.automatica[8]
                            } else if (volume <= 25000000 && volume > 20000000) {
                                return this.automatica[9]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        } else if (somfyAcceptor === 'Cо встроенным приемником') {
                            if (volume <= 5000000) {
                                return this.automatica[10]
                            } else if (volume <= 10000000 && volume > 5000000) {
                                return this.automatica[10]
                            } else if (volume <= 20000000 && volume > 10000000) {
                                return this.automatica[11]
                            } else if (volume <= 25000000 && volume > 20000000) {
                                return this.automatica[11]
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                    }
                }
            }
        } else if (type === 'Ручное'){
            return 'ручное управление'
        } else if ( model === 'Traffico' || model === 'ASN 130 Эконом') {
            return 'ручное управление'
        }
        return 'Без управления'
    }

    async dataInitialization(data: FormValues) {

        let items: Item[] = []
        let additionalItems: AdditionalItem[] = []

        for (let item of data.items) {
            let itemName: string
            let itemMontageType: string
            if (item.model === 'Выставочный стенд') {
                itemName = 'Выставочный стенд'
                itemMontageType = ''
            } else {
                switch (item.model) {
                    case "ASN 130 GPZ":
                        itemName = 'Вертикальные маркизы (экраны) ASN 130 GPZ.\n'
                        break
                    case "ASN 110 cab":
                        itemName = 'Вертикальные маркизы (экраны) ASN 110 cabrio.\n'
                        break
                    case "ASN 130 GPZ TENS":
                        itemName = 'Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n'
                        break
                    case "ASN 130 Эконом":
                        itemName = 'Вертикальные маркизы (экраны) ASN 130 Эконом.\n'
                        break
                    case "Тент на люверсах":
                        itemName = 'Тент на люверсах.\n'
                        break
                    case 'Helix':
                        itemName = 'Локтевые маркизы Helix.\n'
                        break
                    case 'Optima':
                        itemName = 'Локтевые маркизы Optima.\n'
                        break
                    case 'Optima PLUS':
                        itemName = 'Локтевые маркизы Optima PLUS.\n'
                        break
                    case 'VIKTORY':
                        itemName = 'Локтевые маркизы VIKTORY.\n'
                        break
                    case 'Гармония':
                        itemName = 'Локтевые маркизы Гармония.\n'
                        break
                    case 'Domea':
                        itemName = 'Локтевые маркизы Domea.\n'
                        break
                    case 'CLASSIC':
                        itemName = 'Купольные маркизы CLASSIC.\n'
                        break
                    case 'MODERN':
                        itemName = 'Купольные маркизы MODERN.\n'
                        break
                    case 'ITALIA':
                        itemName = 'Витринные маркизы ITALIA.\n'
                        break
                    case 'Traffico':
                        itemName = 'Пергольная маркиза Traffico.\n'
                        break
                    default:
                        itemName = 'Маркиза.\n'
                }
                itemMontageType = 'стена, на проем.'
            }
            if (item.mountType) {
                itemMontageType = item.mountType
            }

            const electricDriver = this.electricDriverSelection(item.width, item.height, item.controlsFactory, item.automaticFactory, item.control, item.controlAcceptor, item.model)

            items.push({
                name: itemName,
                width: item.width,
                height: item.height,
                montageType: itemMontageType,
                tentManufactor: item.factory,
                tentArticle: item.article,
                tentControlSide: item.article2,
                automaticManufacture: item.automaticFactory,
                electricDriverManufacture: item.controlsFactory,
                electricDriver: electricDriver,
                electricDriverSide: item.controlSide,
                color: item.color,
                volan: item.volan,
                travers: item.travers,
                triangle: item.triangle,
                SI: 'компл.',
                priceEuro: await this.countPriceOfItem(item),
                dealerPrice: await this.countPriceOfItem(item, this.userData.dealerDiscounts),
                recommendedPriceEuro: await this.countPriceOfItem(item, undefined,true),
                notes: item.notes
            })
            const RecPriceCof = this.GetRecomendedPriceMultipler(item.model)
            if (itemName !== 'Выставочный стенд' && itemName !== 'Тент на люверсах.\n' && itemName !== 'Пергольная маркиза Traffico.\n') {
                if (item.automaticFactory === 'AC инженеринг' || (item.controlsFactory === 'AC инженеринг' && item.automaticFactory !== 'Somfy' && item.automaticFactory !== 'Cherubini')) {
                    if(item.automaticFactory !== 'Без автоматики') {
                        switch (item.model) {
                            case 'ASN 130 GPZ':
                            case 'ASN 110 cab':
                            case 'ASN 130 GPZ TENS': {
                                additionalItems.push({
                                    description: 'Приемник одноканальный Radio 8113 micro',
                                    quantity: 1,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((23 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                    dealerPrice:
                                        Math.ceil((23 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                    recommendedPriceEuro:
                                        Math.ceil(((23 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                    ref: items.length - 1
                                })
                                break;
                            }
                            case 'Optima PLUS':
                            case 'Optima':
                            case 'Helix':
                            case 'VIKTORY':
                            case 'ITALIA': {
                                if((item.pilotType === 'Одноканальный' || item.pilotType === 'Четырехканальный')) {
                                    additionalItems.push({
                                        description: 'Приемник WSRC006 433 MHz 400W/Receiver',
                                        quantity: 1,
                                        SI: 'шт.',
                                        priceEuro:
                                            Math.ceil((18 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                        dealerPrice:
                                            Math.ceil((18 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                        recommendedPriceEuro:
                                            Math.ceil(((18 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                        ref: items.length - 1
                                    })
                                } else {
                                    additionalItems.push({
                                        description: 'Приемник одноканальный Radio 8113 IP65',
                                        quantity: 1,
                                        SI: 'шт.',
                                        priceEuro:
                                            Math.ceil((32 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                        dealerPrice:
                                            Math.ceil((32 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                        recommendedPriceEuro:
                                            Math.ceil(((32 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                        ref: items.length - 1
                                    })
                                }
                                break;
                            }
                            default: {
                                additionalItems.push({
                                    description: 'Приемник одноканальный Radio 8113 IP65',
                                    quantity: 1,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((32 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                    dealerPrice:
                                        Math.ceil((32 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                    recommendedPriceEuro:
                                        Math.ceil(((32 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                    ref: items.length - 1
                                })
                            }
                        }
                    }
                    if (item.pilotQuantity > 0) {
                        switch (item.pilotType){
                            case 'Одноканальный': {
                                if (item.model === 'Optima PLUS' || item.model === 'Optima' || item.model === 'Helix' || item.model === 'VIKTORY' || item.model === 'ITALIA') {
                                    additionalItems.push({
                                        description: 'Пульт одноканальный WSRE101/Emitter',
                                        quantity: item.pilotQuantity,
                                        SI: 'шт.',
                                        priceEuro:
                                            Math.ceil((14 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                        dealerPrice:
                                            Math.ceil((14 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                        recommendedPriceEuro:
                                            Math.ceil(((14 / this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                        ref: items.length-1
                                    })
                                } else {
                                    additionalItems.push({
                                        description: 'Пульт-брелок одноканальный Radio 8101-1М',
                                        quantity: item.pilotQuantity,
                                        SI: 'шт.',
                                        priceEuro:
                                            Math.ceil((8 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                        dealerPrice:
                                            Math.ceil((8 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                        recommendedPriceEuro:
                                            Math.ceil(((8 / this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                        ref: items.length-1
                                    })
                                }
                                break;
                            }
                            case 'Четырехканальный': {
                                additionalItems.push({
                                    description: 'Пульт четырехканальный WSRE102/ Emitter; Four Channels',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((17 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((17 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((17/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятиканальный': {
                                additionalItems.push({
                                    description: 'Пульт пятиканальный Radio 8101-5',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((15 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((15 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((15/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятнадцатиканальный': {
                                additionalItems.push({
                                    description: 'Пульт пятнадцатиканальный Radio 8101-15',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((18 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((18 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((18/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                        }
                    }
                } else if (item.automaticFactory === 'Somfy' || (item.controlsFactory === 'Somfy' && item.automaticFactory !== 'AC инженеринг' && item.automaticFactory !== 'Cherubini')) {
                    if(item.automaticFactory !== 'Без автоматики') {
                        if (item.model !== 'Domea' && item.model !== 'Гармония' && item.controlAcceptor !== 'Cо встроенным приемником') {
                            additionalItems.push({
                                description: 'Радиоприемник Somfy RTS универсальный в коробке монтажной',
                                quantity: 1,
                                SI: 'шт.',
                                priceEuro:
                                    Math.ceil((119 * this.autCf.somfy * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                dealerPrice:
                                    Math.ceil((119 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                recommendedPriceEuro:
                                    Math.ceil(((119 * this.autCf.somfy / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                ref: items.length - 1
                            })
                        }
                    }
                    if (item.pilotQuantity > 0) {
                        switch (item.pilotType){
                            case 'Одноканальный': {
                                additionalItems.push({
                                    description: 'Пульт Somfy Situo 1 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((40 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((40 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((40 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятиканальный': {
                                additionalItems.push({
                                    description: 'Пульт Somfy Situo 5 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((79 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((79 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((79 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятнадцатиканальный': {
                                additionalItems.push({
                                    description: 'Пульт TELIS 16 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((145 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((145 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((145 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                        }
                    }

                } else if (item.automaticFactory === 'Cherubini' || (item.controlsFactory === 'Cherubini' && item.automaticFactory !== 'AC инженеринг' && item.automaticFactory !== 'Somfy')) {
                    if(item.automaticFactory !== 'Без автоматики') {
                        if (item.model !== 'ASN 110 cab' && item.model !== 'ASN 130 GPZ' && item.model !== 'Domea' && item.model !== 'Гармония' && item.model !== 'Optima PLUS' && item.model !== 'Optima' && item.model !== 'Helix' && item.model !== 'ITALIA' && item.model !== 'VIKTORY' && item.controlAcceptor !== 'Cо встроенным приемником') {
                            additionalItems.push({
                                description: 'Приемник мини ROLLING SHUTT',
                                quantity: 1,
                                SI: 'шт.',
                                priceEuro:
                                    Math.ceil((84 * this.autCf.cherubini * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                dealerPrice:
                                    Math.ceil((84 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                recommendedPriceEuro:
                                    Math.ceil(((84 * this.autCf.cherubini / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                ref: items.length - 1
                            })
                        }
                        if (item.model === 'ASN 110 cab' || item.model === 'ASN 130 GPZ' || item.model === 'Optima PLUS' || item.model === 'Optima' || item.model === 'Helix' || item.model === 'ITALIA' || item.model === 'VIKTORY'){
                            additionalItems.push({
                                description: 'Приемник UNIT',
                                quantity: 1,
                                SI: 'шт.',
                                priceEuro:
                                    Math.ceil((103 * this.autCf.cherubini * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                dealerPrice:
                                    Math.ceil((103 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                recommendedPriceEuro:
                                    Math.ceil(((103 * this.autCf.cherubini / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                ref: items.length - 1
                            })
                        }
                    }
                    //  if (item.model === 'Domea' || item.model === 'Гармония') {
                    //      additionalItems.push({
                    //          description: 'Приемник UNIT',
                    //          quantity: 1,
                    //          SI: 'шт.',
                    //          priceEuro:
                    //              Math.ceil((82 * this.autCf.cherubini * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                    //          dealerPrice:
                    //              Math.ceil((82 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                    //          recommendedPriceEuro:
                    //              Math.ceil(((82 * this.autCf.cherubini / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                    //          ref: items.length - 1
                    //      })
                    // }
                    if (item.pilotQuantity > 0) {
                        switch (item.pilotType){
                            case 'Пульт POP COOL одноканальный': {
                                additionalItems.push({
                                    description: 'Пульт POP COOL одноканальный',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((48 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((48 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((48 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пульт GIRO MONO одноканальный': {
                                additionalItems.push({
                                    description: 'Пульт GIRO MONO  одноканальный ',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((56 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((56 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((56 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пульт POP PLUS многоканальный': {
                                additionalItems.push({
                                    description: 'Пульт POP PLUS многоканальный',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((61 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((61 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((61 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пульт GIRO PLUS многоканальный': {
                                additionalItems.push({
                                    description: 'Пульт GIRO PLUS многоканальный',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((68 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((68 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((68 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                        }
                    }
                }
                if (item.automaticAddition === 'Датчик ветра') {
                    additionalItems.push({
                        description: 'Датчик ветра Somfy Eolis Sensor RTS (датчик ветра, радиопередатчик)',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((109 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((109 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((109 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Датчик ветра и солнца WSRS002/ Wind-Light Sensor') {
                    additionalItems.push({
                        description: 'Датчик ветра и солнца WSRS002/ Wind-Light Sensor',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((57 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((57 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((57 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Автономный датчик ветра WSRS005/ Motion Sensor') {
                    additionalItems.push({
                        description: 'Автономный датчик ветра WSRS005/ Motion Sensor',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((35 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((35 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((35 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Автономный датчик ветра Mistarl') {
                    additionalItems.push({
                        description: 'Автономный датчик ветра Mistarl',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((112 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((112 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((112 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Датчик ветра WINDTEC WIND SENSOR') {
                    additionalItems.push({
                        description: 'Датчик ветра WINDTEC WIND SENSOR',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((88 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((88 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((88 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Датчик ветра и солнца LUX WINDTEC LUX WIND SENSOR') {
                    additionalItems.push({
                        description: 'Датчик ветра и солнца LUX WINDTEC LUX WIND SENSOR',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((100 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((100 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((100 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.antiWind === 'Добавить') {
                    let quantity = 2
                    if (item.width < 7000) {
                        quantity = 2
                    } else if (item.width < 9000) {
                        quantity = 4
                    } else {
                        quantity = 6
                    }
                    additionalItems.push({
                        description: 'Антиветровая стойка',
                        quantity: quantity,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((75 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((75 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((75/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.rainSensor === 'Добавить') {
                    additionalItems.push({
                        description: 'Датчик дождя RUGIADA TX RADIO RAIN-SENSOR',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((166 * this.autCf.cherubini * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((166 * this.autCf.cherubini * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((166 * this.autCf.cherubini/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Автономный датчик ветра') {
                    additionalItems.push({
                        description: 'Датчик ветра автономный EOLIS 3D WIREFREE RTS',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((107 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((107 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((107 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.vorotok && item.vorotok !== 'Без воротка') {
                    let price = 0
                    switch (item.vorotok) {
                        case 'Вороток 1.5 м':
                            price = 4.40
                            break
                        case 'Вороток 2 м':
                            price = 4.84
                            break
                        case 'Вороток 2.5 м':
                            price = 5.17
                            break
                        default:
                            break
                    }
                    additionalItems.push({
                        description: item.vorotok,
                        quantity: item.vorotokQuantity,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((price * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((price * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((price/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.server === 'Добавить') {
                    additionalItems.push({
                        description: 'Устройство центральное 8767',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((40 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((40 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((40 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.swb === 'SWB') {
                    additionalItems.push({
                        description: 'Выключатель клавишный SWB для накладного монтажа',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((3.5 * this.autCf.somfy * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((3.5 * this.autCf.somfy * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((3.5 * this.autCf.somfy/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.swb === 'Radio 8103') {
                    additionalItems.push({
                        description: 'Настенный пульт Radio 8103 (3-х канальный)',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((21 * this.autCf.ac * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((21 * this.autCf.ac * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((21 * this.autCf.ac/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

            } else if (itemName === 'Тент на люверсах.\n'){
                if(item.belts !== 0) {
                    additionalItems.push({
                        description: 'Ремешок 350 мм.',
                        quantity: item.belts,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((2.7 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((2.7 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((2.7/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }

                let zipSize = item.zip
                if(zipSize !== 0 && !isNaN(zipSize)){
                    additionalItems.push({
                        description: 'Молния',
                        quantity: zipSize,
                        SI: 'м.',
                        priceEuro:
                            Math.ceil((8 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((8 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((8/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }

            }
            if(item.model === 'Helix' || item.model === 'Optima') {
                if(item.width-500 < item.height){
                    additionalItems.push({
                        description: 'Комплект для смещения локтей',
                        quantity: 1,
                        SI: 'м.',
                        priceEuro:
                            this.colored ? Math.ceil((84*1.1 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100 : Math.ceil((84 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            this.colored ? Math.ceil((84*1.1 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100 : Math.ceil((84 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                        //ASK
                            this.colored ? Math.ceil((((84*1.1)/this.NDS.sub)*RecPriceCof.model)*100)/100 : Math.ceil(((84/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }
            }

            if(item.ads === 'Добавить'){
                const square = Math.ceil((item.width * item.height)/1000000)
                //const price = square * 32
                additionalItems.push({
                    description: `Нанесение рекламы ${square}м.кв (ЦЕНА СОГЛАСОВЫВАЕТСЯ ДОПОЛНИТЕЛЬНО)`,
                    quantity: 1,
                    SI: 'м.',
                    priceEuro:
                        0,
                    dealerPrice:
                        0,
                    recommendedPriceEuro:
                        0,
                    ref: items.length-1
                })
            }

            if(item.triangle === 'С одной стороны' || item.triangle === 'С двух сторон') {
                console.log('sdfdsfnsdobhfosdb sdnfs ', item.triangle )
                let price = 0
                if (item.triangle === 'С одной стороны') {
                    price = Math.ceil(item.height / 1000) * 25
                } else {
                    price = 2 * Math.ceil(item.height / 1000) * 25
                }
                additionalItems.push({
                    description: 'Заполнение треугольник',
                    quantity: 1,
                    SI: 'компл.',
                    priceEuro:
                        Math.ceil((price * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((price * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((price/this.NDS.sub)*RecPriceCof.model)*100)/100,
                    ref: items.length-1
                })
            }

            if(item.alarm === 'Добавить') {
                additionalItems.push({
                    description: `Аварийное ручное открывание`,
                    quantity: 1,
                    SI: 'шт.',
                    priceEuro:
                        Math.ceil((65 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((65 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((65 * this.autCf.traffico/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                    ref: items.length-1
                })
            }

            if(item.led === 'Добавить'){
                let price = 0
                switch (item.model) {
                    case 'Optima': case 'Гармония': case 'VIKTORY': case 'Domea': case 'Helix': {
                        price = 0
                        if (item.width <= 6000) {
                            price = 430
                        } else if (item.width <= 8000 && item.width > 6000) {
                            price = 640
                        } else if (item.width <= 12000 && item.width > 8000) {
                            price = 890
                        }
                        break;
                    }
                    case 'Traffico': {
                        const additionalPrice = item.width * item.travers >= 12000 ? 200 : 100
                        price = Math.ceil(item.travers * item.width * 0.0098) + (Math.ceil((item.travers * item.width) / 15000) * 60) + 80 + additionalPrice
                        break;
                    }
                }

                if (item.model === 'Traffico') {
                    additionalItems.push({
                        description: `Комплект LED освещения`,
                        quantity: 1,
                        SI: 'м.',
                        priceEuro:
                            Math.ceil((price * this.autCf.traffico * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((price * this.autCf.traffico * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                        //ASK
                            Math.ceil(((price * this.autCf.traffico/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                } else {
                    additionalItems.push({
                        description: `Комплект LED освещения`,
                        quantity: 1,
                        SI: 'м.',
                        priceEuro:
                            Math.ceil((price * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((price * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                        //ASK
                            Math.ceil(((price/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
            }

            if(item.additionalDriver === 'Добавить') {
                additionalItems.push({
                    description: `Дополнительный электропривод V220`,
                    quantity: 1,
                    SI: 'м.',
                    priceEuro:
                        Math.ceil((621 * this.autCf.traffico * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((621 * this.autCf.traffico * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((621 * this.autCf.traffico/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                    ref: items.length-1
                })
            }

            if(item.cap === 'Добавить'){
                let price = 0
                switch (item.model) {
                    case 'Helix': {
                        if (item.width <= 3500) {
                            price = 231
                        } else {
                            price = 410
                        }
                        break
                    }
                    case 'VIKTORY': {
                        if (item.width <= 6000) {
                            price = 661
                        } else {
                            price = 1254
                        }
                        break
                    }
                    case 'Optima': {
                        if (item.width <= 3500) {
                            price = 230
                        } else if (item.width <= 7000) {
                            price = 410
                        } else if (item.width <= 10500){
                            price = 511
                        } else {
                            price = 695
                        }
                        break
                    }
                    case 'Optima PLUS': {
                        if (item.width <= 3500) {
                            price = 231
                        } else if (item.width <= 7000) {
                            price = 410
                        } else if (item.width <= 10500){
                            price = 511
                        } else {
                            price = 695
                        }
                        break
                    }
                }

                price = this.colored ? price*1.1 : price

                additionalItems.push({
                    description: `Комплект укрывающей крышки`,
                    quantity: 1,
                    SI: 'компл.',
                    priceEuro:
                        Math.ceil((price * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((price * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((price/this.NDS.sub)*RecPriceCof.model)*100)/100,
                    ref: items.length-1
                })
            }
        }
        return {
            items: items,
            additionalItems: additionalItems,
            rate: await this.getRate(this.userData.country)
        }
    }

    async getListOfArticles(company: string) {
        const SaveSystem = new SaveSystemService()
        const art = await SaveSystem.getArticles()
        if (art[0].company === company){
            return art[0].data
        } else {
            return art[1].data
        }

    }

    async getRate(companyCountry: string) {
        return 1
        if (companyCountry === 'Беларусь') {
            const res = await axios.create().get('https://www.nbrb.by/api/exrates/rates/EUR?parammode=2')
            console.log(res.data.Cur_OfficialRate)

            return Math.ceil((res.data.Cur_OfficialRate * 1.02)*100)/100

        } else if (companyCountry === 'Россия') {
            const res = await axios.create().get('https://www.cbr-xml-daily.ru/daily_json.js')
            console.log(res.data.Valute.EUR.Value)

            return Math.ceil((res.data.Valute.EUR.Value * 1.02)*100)/100

        } else if (companyCountry === 'Казахстан') {
            if (this.userData.role === 'Dealer'){
                return 1
            } else {
                const res = await axios.create().get('https://free.currencyconverterapi.com/api/v5/convert?q=EUR_KZT&compact=ultra&apiKey=737080e8b43cba5bccd0')
                console.log(res.data.EUR_KZT)

                return Math.ceil((res.data.EUR_KZT * 1.02)*100)/100
            }
        } else {
            return 0
        }
    }

}