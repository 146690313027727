import React, {useEffect, useState} from "react";
import {DefaultValues, useFieldArray, useForm} from "react-hook-form"
import {FormValues} from "../../../types/types";
import SaveSystemService from "../../../services/save-system-service";
import {Link} from "react-router-dom";

type FormElementsProps = {
    onSubmit: (data: FormValues) => void,
    mail: string,
    savedOrderData?: DefaultValues<FormValues>,
    callPopup: (message: string, type: string) => void
}

interface KeyValueArr {
    [key: string]: {
        min: number,
        max: number
    }
}

const OrderFormElements = ({ onSubmit, savedOrderData, mail, callPopup }: FormElementsProps) => {

    const [isSaved, setIsSaved] = useState(false)
    const [articles, setArticles] = useState()

    const { register, control, watch, reset, handleSubmit } = useForm<FormValues>({
        mode: 'onChange',
        shouldUnregister: true
    })

    const { fields, append, remove, update } = useFieldArray({
        name: "items",
        control
    })

    const SaveSystem = new SaveSystemService()

    useEffect(() => {
        append({})
    }, [append]);

    useEffect(() => {
        if(savedOrderData){
            reset(savedOrderData)
        }
    }, [reset]);

    useEffect(() => {
        getArticles()
    }, [])

    const widthBoundaries: KeyValueArr = {
        'Traffico': {
            min: 3000,
            max: 13000
        },
        'CLASSIC': {
            min: 500,
            max: 7000
        },
        'MODERN': {
            min: 500,
            max: 4000
        },
        'Helix': {
            min: 1300,
            max: 6000
        },
        'Optima': {
            min: 1300,
            max: 12000
        },
        'Optima PLUS': {
            min: 2100,
            max: 12000
        },
        'Гармония': {
            min: 2100,
            max: 12000
        },
        'VIKTORY': {
            min: 4600,
            max: 12000
        },
        'Domea': {
            min: 2100,
            max: 6000
        },
        'ITALIA': {
            min: 500,
            max: 5000
        },
        'ASN 130 GPZ': {
            min: 600,
            max: 5000
        },
        'ASN 110 cab': {
            min: 600,
            max: 4000
        },
        'ASN 130 GPZ TENS': {
            min: 600,
            max: 5000
        },
        'ASN 130 Эконом': {
            min: 1000,
            max: 3500
        },
        'Тент на люверсах': {
            min: 0,
            max: 100000
        }
    }

    const heightBoundaries: KeyValueArr = {
        'Traffico': {
            min: 3000,
            max: 6000
        },
        'CLASSIC': {
            min: 500,
            max: 1800
        },
        'MODERN': {
            min: 500,
            max: 1800
        },
        'Helix': {
            min: 200,
            max: 3600
        },
        'Optima': {
            min: 200,
            max: 4100
        },
        'Optima PLUS': {
            min: 200,
            max: 3100
        },
        'Гармония': {
            min: 200,
            max: 4350
        },
        'VIKTORY': {
            min: 200,
            max: 5650
        },
        'Domea': {
            min: 200,
            max: 3100
        },
        'ITALIA': {
            min: 200,
            max: 1600
        },
        'ASN 130 GPZ': {
            min: 600,
            max: 5000
        },
        'ASN 110 cab': {
            min: 600,
            max: 3000
        },
        'ASN 130 GPZ TENS': {
            min: 600,
            max: 5000
        },
        'ASN 130 Эконом': {
            min: 1400,
            max: 3000
        },
        'Тент на люверсах': {
            min: 0,
            max: 100000
        }
    }

    const getArticles = () => {
        SaveSystem.getArticles().then((art) => {
            setArticles(art)
        })
    }

    const SaveOrder = () => {
         SaveSystem.saveOrder(mail, watch(), 'Сохранён').then((res) => {
            if(res) {
                callPopup('Заказ успешно сохранен!', 'success')
                setIsSaved(true)
            } else {
                callPopup('Заказ не был сохранен!', 'error')
            }
        })
    }

    const isColorDisplayed = (index: number) => {
        const model = watch().items[index].model
        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд':{
                return false
            }
            default: {
                return true
            }
        }
    }

    const isControlDisplayed = (index: number) => {
        const factory = watch().items[index].factory
        const model = watch().items[index].model

        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд': case 'Traffico': case 'ASN 130 Эконом':{
                return false
            }
            default: {
                return factory !== '';
            }
        }
    }

    const isControlSideDisplayed = (index: number) => {
        const factory = watch().items[index].factory
        const model = watch().items[index].model

        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд': case 'Traffico':{
                return false
            }
            default: {
                return factory !== '';
            }
        }
    }

    const isFactoryDisplayed = (index: number) => {
        const model = watch().items[index].model
        switch (model) {
            case 'Выставочный стенд':{
                return false
            }
            default: {
                return true
            }
        }
    }

    const isAutomaticFactoryDisplayed = (index: number) => {
        const controlsFactory = watch().items[index].controlsFactory
        const controlAcceptor = watch().items[index].controlAcceptor

        return controlAcceptor === '-' || controlsFactory === 'AC инженеринг';
    }

    const isSwbDisplayed = (index: number) => {
        const controlsFactory = watch().items[index].controlsFactory
        const controlAcceptor = watch().items[index].controlAcceptor

        return controlAcceptor === '-' || controlsFactory === 'AC инженеринг';
    }

    const isPilotQuantityDisplayed = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlAceptor = watch().items[index].controlAcceptor
        const controlsFactory = watch().items[index].controlsFactory

        return model !== 'Выставочный стенд' && model !== 'Traffico' && model !== 'Тент на люверсах' && (automaticFactory || controlAceptor === 'Cо встроенным приемником' || (controlsFactory === 'Cherubini' && (model === 'ASN 130 GPZ' || model === 'ASN 110 cab')));
    }

    const isPilotTypeDisplayed = (index: number) => {
        if (!(watch().items[index].pilotQuantity >= 0)){
            return false
        }

        const model = watch().items[index].model
        const controlsFactory = watch().items[index].controlsFactory

        return watch().items[index].automaticFactory || watch().items[index].controlAcceptor === 'Cо встроенным приемником' || (controlsFactory === 'Cherubini' && (model === 'ASN 130 GPZ' || model === 'ASN 110 cab'))
    }

    const isAutomaticAdditionDisplayed = (index: number) => {
        const { controlsFactory, automaticFactory, model, pilotType  } = watch().items[index]
        return automaticFactory === 'Somfy' ||
            automaticFactory === 'Cherubini' ||
            (controlsFactory === 'Somfy' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Cherubini') ||
            (controlsFactory === 'Cherubini' && automaticFactory !== 'AC инженеринг'  && automaticFactory !== 'Somfy') ||
            ((controlsFactory === 'AC инженеринг' && automaticFactory !== 'Cherubini'  && automaticFactory !== 'Somfy') && (model === 'ITALIA' || model === 'Optima' || model === 'Helix' || model === 'VIKTORY' || model === 'Optima PLUS') && (pilotType === 'Одноканальный' || pilotType === 'Четырехканальный'))
    }

    const isMountTypeDisplayed = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': case 'Domea': case 'ASN 130 Эконом':{
                return true
            }
            default: {
                return false
            }
        }
    }

    const mountTypeText = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': case 'ASN 130 Эконом':
                return 'Вид монтажа:'
            default:
                return 'Тип крепления:'

        }
    }

    const mountTypeSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': case 'ASN 130 Эконом':
                return (
                    <React.Fragment>
                        <option>Накладной</option>
                        <option>Встроенный</option>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <option>Потолочное</option>
                        <option>Стеновое</option>
                    </React.Fragment>
                )

        }
    }

    const isVolanDisplayed = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'MODERN': case 'CLASSIC': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': {
                return true
            }
            default: {
                return false
            }
        }
    }

    const isRainSensorDisplayed = (index: number) => {
        const model = watch().items[index].model
        const controlsFactory = watch().items[index].controlsFactory
        const automaticFactory = watch().items[index].automaticFactory

        if (model !== 'Тент на люверсах' && model !== 'Выставочный стенд') {
            return automaticFactory === 'Cherubini' || (controlsFactory === 'Cherubini' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Somfy');
        }
        return false
    }

    const isAntiWindDisplayed = (index: number) => {
        const model = watch().items[index].model
        const control = watch().items[index].control
        const swb = watch().items[index].swb
        const wind = watch().items[index].automaticAddition
        const pilots = watch().items[index].pilotQuantity

        switch (model) {
            case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': {
                if(control === 'Ручное') {
                    return  true
                } else {
                    return swb === 'SWB' && (pilots === 0 || !pilots) && (wind === undefined || wind === '');
                }
            }
            default: {
                return false
            }
        }
    }

    const isCapDisplayed = (index: number) => {
        const mountType = watch().items[index].mountType
        const model = watch().items[index].model

        switch (model) {
            case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': {
                return mountType !== 'Потолочное';
            }
            default: {
                return false
            }
        }
    }

    const isAdsDisplayed = (index: number) => {
        const model = watch().items[index].model
        const volan = watch().items[index].volan
        switch (model) {
            case 'MODERN': case 'CLASSIC': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': {
                return !!((volan !== 'Без волана') && volan)
            }
            default: {
                return false
            }
        }
    }

    const isLEDDisplayed = (index: number) => {
        const model = watch().items[index].model
        const height = watch().items[index].height
        const controlsFactory = watch().items[index].controlsFactory
        const automaticFactory = watch().items[index].automaticFactory

        if (model === 'Traffico') {
            return true
        }

        if (
            automaticFactory === 'Somfy' ||
            automaticFactory === 'Cherubini' ||
            (controlsFactory === 'Somfy' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Cherubini') ||
            (controlsFactory === 'Cherubini' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Somfy')
        ) {
            switch (model) {
                case 'Optima': {
                    return height >= 3100
                }
                case 'Helix': {
                    return height >= 3100
                }
                case 'VIKTORY':
                case 'Гармония':
                case 'Domea': {
                    return true
                }
                default: {
                    return false
                }
            }
        } else {
            return false
        }
    }

    const isMiniServerDisplayed = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlsFactory = watch().items[index].controlsFactory
        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab':
                if (automaticFactory === 'AC инженеринг' && controlsFactory === 'AC инженеринг') {
                    return true
                } else {
                    return false
                }
            default:
                return  false
        }
    }

    const traverseSelect = (index: number) => {
        const height = watch().items[index].height

        if (height <= 3000) {
            return(
                <React.Fragment>
                    <option/>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                </React.Fragment>
            )
        } else if (height <= 4000) {
            return(
                <React.Fragment>
                    <option/>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                </React.Fragment>
            )
        } else {
            return(
                <React.Fragment>
                    <option/>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                </React.Fragment>
            )
        }
    }

    const tentArticleSelect = (index: number) => {
        const model = watch().items[index].model
        const item = watch().items[index]
        const factory = watch().items[index].factory

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': {
                return (
                    <div className="input-divs">
                        <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                        <select id={`items.${index}.article`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue=''>
                            {
                                articleSelect(index)
                            }
                        </select>
                    </div>
                )
            }
            case 'Traffico': {
                return (
                    <div className="input-divs">
                        <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                        <select id={`items.${index}.article`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue=''>
                            <option/>
                            <option>Белый</option>
                            <option>Бежевый</option>
                            <option>Темно серый</option>
                        </select>
                    </div>
                )
            }
            case 'ASN 130 Эконом': {
                return (
                    <div className="input-divs">
                        <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                        <select id={`items.${index}.article`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue=''>
                            <option/>
                            <option>109-02</option>
                            <option>109-13</option>
                            <option>109-03</option>
                            <option>109-06</option>
                        </select>
                    </div>
                )
            }
            default: {
                if (factory === 'Dickson акриловые ткани'){
                    return (
                        <div className="input-divs">
                            <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                            <input  autoComplete='off' type="search" id={`items.${index}.article`} list={`items.${index}.articleOptions`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue=''/>
                            <datalist id={`items.${index}.articleOptions`}>
                                {
                                    articleSelect(index)
                                }
                            </datalist>
                        </div>
                    )
                } else {
                    return (
                        <div className="input-divs">
                            <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                            <select id={`items.${index}.article`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue=''>
                                {articleSelect(index)}
                            </select>
                        </div>
                    )
                }
            }
        }
    }

    const controlsFactorySelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'VIKTORY': case 'Гармония': case 'Domea': case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Somfy</option>
                        <option>Cherubini</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>AC инженеринг</option>
                        <option>Somfy</option>
                        <option>Cherubini</option>
                    </React.Fragment>
                )
            }

        }
    }

    const automaticFactorySelect = (index: number) => {
        const model = watch().items[index].model
        const controlsFactory = watch().items[index].controlsFactory

        switch (model) {
            case 'ASN 130 GPZ TENS': case 'ASN 110 cab': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': {
                return (
                    <React.Fragment>
                        <option>Без автоматики</option>
                        <option>AC инженеринг</option>
                        <option>Somfy</option>
                        <option>Cherubini</option>
                    </React.Fragment>
                )
            }
            default: {
                if (controlsFactory === 'Somfy') {
                   return (
                       <React.Fragment>
                           <option>Без автоматики</option>
                           <option>AC инженеринг</option>
                           <option>Somfy</option>
                           <option>Cherubini</option>
                       </React.Fragment>
                   )
                } else {
                    return(
                        <React.Fragment>
                            <option>Без автоматики</option>
                            <option>AC инженеринг</option>
                            <option>Cherubini</option>
                        </React.Fragment>
                    )
                }
            }
        }
    }

    const factorySelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония':{
                return (
                    <React.Fragment>
                        <option>Dickson акриловые ткани</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>COPACO</option>
                        <option>Dickson акриловые ткани</option>
                    </React.Fragment>
                )
            }
            case 'Traffico': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Dickson ПВХ</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 Эконом': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Lienesch</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>COPACO</option>
                        <option>Dickson акриловые ткани</option>
                        <option>Crystal</option>
                    </React.Fragment>
                )
            }
        }
    }

    const colorSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': {
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9010(белый)">Ral 9010(белый)</option>
                    </React.Fragment>
                )
            }
            case 'Traffico': {
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9010(белый)">Ral 9010(белый)</option>
                        <option value="Ral 9004(черный)">Ral 9004(ченрный)</option>
                        <option value="Ral 8017(коричневый)">Ral 8017(коричневый)</option>
                        <option value="Ral 7024(антрацит)">Ral 7024(антрацит)</option>
                        <option value="Ral 1024(бежевый)">Ral 1024(бежевый)</option>
                        <option value="Ral 1013(жемчужный)">Ral 1013(жемчужный)</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 GPZ': {
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9016(белый)">Ral 9016(белый)</option>
                        <option value="Ral 9004(черный)">Ral 9004(черный)</option>
                        <option value="Ral 8017(коричневый)">Ral 8017(коричневый)</option>
                        <option value="Ral 1024(бежевый)">Ral 1024(бежевый)</option>
                        <option value="Ral 7024(антрацит)">Ral 7024(антрацит)</option>
                        <option value="Ral 1013(жемчужный)">Ral 1013(жемчужный)</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9010(белый)">Ral 9010(белый)</option>
                        <option value="Ral 9004(черный)">Ral 9004(черный)</option>
                        <option value="Ral 8017(коричневый)">Ral 8017(коричневый)</option>
                        <option value="Ral 1024(бежевый)">Ral 1024(бежевый)</option>
                        <option value="Ral 7024(антрацит)">Ral 7024(антрацит)</option>
                        <option value="Ral 1013(жемчужный)">Ral 1013(жемчужный)</option>
                    </React.Fragment>
                )
            }

        }
    }

    const swbSelect = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlsFactory = watch().items[index].controlsFactory

        if((model === 'ASN 130 GPZ' || model === 'ASN 110 cab') && (automaticFactory === 'AC инженеринг' || controlsFactory === 'AC инженеринг')) {
            return (
                <React.Fragment>
                    <option>-</option>
                    <option>Radio 8103</option>
                    <option>SWB</option>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <option>-</option>
                    <option>SWB</option>
                </React.Fragment>
            )
        }
    }

    const automaticAdditionSelect = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlsFactory = watch().items[index].controlsFactory

        if(automaticFactory === 'AC инженеринг' || (controlsFactory === 'AC инженеринг' && automaticFactory !== 'Cherubini' && automaticFactory !== 'Somfy')) {
            return (
                <React.Fragment>
                    <option />
                    <option>Датчик ветра и солнца WSRS002/ Wind-Light Sensor</option>
                    <option>Автономный датчик ветра WSRS005/ Motion Sensor</option>
                </React.Fragment>
            )
        } else if (automaticFactory === 'Cherubini' || (controlsFactory === 'Cherubini' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Somfy')) {
            switch (model) {
                case "Domea": case "Гармония": case 'Optima': case 'ITALIA': case 'Optima PLUS': case 'VIKTORY': case 'Helix':{
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автономный датчик ветра Mistarl</option>
                            <option>Датчик ветра WINDTEC WIND SENSOR</option>
                            <option>Датчик ветра и солнца LUX WINDTEC LUX WIND SENSOR</option>
                        </React.Fragment>
                    )
                }
                default: {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Датчик ветра WINDTEC WIND SENSOR</option>
                            <option>Датчик ветра и солнца LUX WINDTEC LUX WIND SENSOR</option>
                        </React.Fragment>
                    )
                }
            }
        } else {
            switch (model) {
                case "Domea": case "Гармония": case 'Optima': case 'ITALIA': case 'Optima PLUS': case 'VIKTORY': case 'Helix':{
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автономный датчик ветра</option>
                            <option>Датчик ветра</option>
                        </React.Fragment>
                    )
                }
                default: {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Датчик ветра</option>
                        </React.Fragment>
                    )
                }
            }
        }
    }

    const pultTypeSelect = (index: number) => {
        const length = watch().items.length
        const model = watch().items[index].model
        const led = watch().items[index].led
        const automaticFactory = watch().items[index].automaticFactory
        const controlsFactory = watch().items[index].controlsFactory

        let select

        if (automaticFactory === 'Cherubini' || (controlsFactory === 'Cherubini' && automaticFactory !== 'AC инженеринг' && automaticFactory !== 'Somfy')) {
            return(
                (length === 1 && led !== 'Добавить') ?
                    <React.Fragment>
                        <option />
                        <option>Пульт POP COOL одноканальный</option>
                        <option>Пульт GIRO MONO одноканальный</option>
                        <option>Пульт POP PLUS многоканальный</option>
                        <option>Пульт GIRO PLUS многоканальный</option>
                    </React.Fragment>
                        :
                    <React.Fragment>
                        <option />
                        <option>Пульт POP PLUS многоканальный</option>
                        <option>Пульт GIRO PLUS многоканальный</option>
                    </React.Fragment>
            )
        } else if(automaticFactory === 'AC инженеринг' && (model === 'ASN 130 GPZ' || model === 'ASN 110 cab')) {
            select = (
                <React.Fragment>
                    <option/>
                    <option>Одноканальный</option>
                    <option>Пятиканальный</option>
                    <option>Пятнадцатиканальный</option>
                </React.Fragment>
            )
        } else if(automaticFactory === 'AC инженеринг' && (model === 'Optima PLUS' || model === 'Optima' || model === 'ITALIA' || model === 'Helix' || model === 'VIKTORY')) {
            select = (
                <React.Fragment>
                    <option/>
                    <option>Одноканальный</option>
                    <option>Четырехканальный</option>
                    <option>Пятиканальный</option>
                    <option>Пятнадцатиканальный</option>
                </React.Fragment>
            )
        } else {
            switch (model) {
                case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS':
                    select = (
                        <React.Fragment>
                            <option />
                            <option>Пятиканальный</option>
                            <option>Пятнадцатиканальный</option>
                        </React.Fragment>
                    )
                    break
                default:
                    select = (
                        <React.Fragment>
                            <option />
                            <option>Одноканальный</option>
                            <option>Пятиканальный</option>
                            <option>Пятнадцатиканальный</option>
                        </React.Fragment>
                    )
            }
        }
        return (
            (length === 1 && led !== 'Добавить') ?
                select
                :
                <React.Fragment>
                    <option />
                    <option>Пятиканальный</option>
                    <option>Пятнадцатиканальный</option>
                </React.Fragment>
        )
    }

    const controlAcceptorSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case "Domea": case "Гармония": case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Cо встроенным приемником</option>
                    </React.Fragment>
                )
            }
            case 'Optima': case 'Optima PLUS': case 'Helix': case 'VIKTORY': case 'ITALIA':{
                return (
                    <React.Fragment>
                        <option />
                        <option>-</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 GPZ':
                const width = watch().items[index].width
                const height = watch().items[index].height
                const square = width*height

                if (square > 20000000 && square <= 25000000){
                    return (
                        <React.Fragment>
                            <option />
                            <option>-</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>-</option>
                            <option>Cо встроенным приемником</option>
                        </React.Fragment>
                    )
                }

            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>-</option>
                        <option>Cо встроенным приемником</option>
                    </React.Fragment>
                )
            }

        }
    }

    const controlSelect = (index: number) => {
        const model = watch().items[index].model
        const width = watch().items[index].width
        const height = watch().items[index].height

        switch (model) {
            case "ASN 130 GPZ TENS": {
                return (
                    <React.Fragment>
                        <option />
                        <option>Автоматическое</option>
                    </React.Fragment>
                )
            }
            case "CLASSIC": case "MODERN": {
                return (
                    <React.Fragment>
                        <option />
                        <option>Ручное</option>
                    </React.Fragment>
                )
            }
            case 'VIKTORY':{
                if (width > 5000 || height > 4600){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            case 'Traffico': {
                if(width < 6000) {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            case 'Гармония':{
                if (width > 7000 || height > 3100){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            case 'Optima': case 'Optima PLUS': {
                if (width > 7000){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>Ручное</option>
                        <option>Автоматическое</option>
                    </React.Fragment>
                )
            }

        }
    }

    const articleSelect = (index: number) => {

        const factory = watch().items[index].factory
        let copaco = []
        let dicksonAcryl = []

        // @ts-ignore
        if (articles[0].company === 'COPACO') {
            // @ts-ignore
            copaco = articles[0].data
            // @ts-ignore
            dicksonAcryl = articles[1].data
        } else {
            // @ts-ignore
            copaco = articles[1].data
            // @ts-ignore
            dicksonAcryl = articles[0].data
        }

        switch (factory) {
            case 'COPACO': {
                return(
                    <React.Fragment>
                        <option/>
                        {copaco.map((option: string) => {
                            return <option key={option}>{option}</option>
                        })}
                    </React.Fragment>
                    )
            }
            case 'Dickson акриловые ткани': {
                return(
                    <React.Fragment>
                        <option/>
                        {dicksonAcryl.map((option: string) => {
                            return <option key={option}>{option}</option>
                        })}
                    </React.Fragment>
                )
            }
            case 'Crystal': {
                return(
                    <React.Fragment>
                        <option/>
                        <option>Мягкое стекло</option>
                    </React.Fragment>
                )
            }
        }
    }

    const heightText = (index:number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': {
                return `Выпуск локтя(${heightBoundaries[watch().items[index].model].min} - ${heightBoundaries[watch().items[index].model].max} мм):`
            }
            case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': {
                return `Выпуск локтя(мм):`
            }
            case 'MODERN': {
                return `Радиус корзины(${heightBoundaries[watch().items[index].model].min} - ${heightBoundaries[watch().items[index].model].max} мм):`
            }
            default: {
                return `Высота(${heightBoundaries[watch().items[index].model].min} - ${heightBoundaries[watch().items[index].model].max} мм):`
            }
        }

    }

    const heightTextInput = (index: number) => {
        const model = watch().items[index].model
        const width = watch().items[index].width

        switch (model) {
            case 'Optima':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option>1600</option>
                        <option>2100</option>
                        <option>2600</option>
                        <option>3100</option>
                        <option>3600</option>
                        <option>4100</option>
                    </select>
                )
            }
            case 'Helix':{
                const width = watch().items[index].width

                if (width <= 5000){
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>2100</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                }
            }
            case 'Optima PLUS':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option disabled={width - 1600 < 500}>1600</option>
                        <option disabled={width - 2100 < 500}>2100</option>
                        <option disabled={width - 2600 < 500}>2600</option>
                        <option disabled={width - 3100 < 500}>3100</option>
                    </select>
                )
            }
            case 'VIKTORY':{
                const width = watch().items[index].width
                if (width <= 5000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                        </select>
                    )
                } else if (width <= 7000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                            <option disabled={width - 5100 < 500}>5100</option>
                            <option disabled={width - 5650 < 500}>5650</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                            <option disabled={width - 5100 < 500}>5100</option>
                        </select>
                    )
                }

            }
            case 'ITALIA':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option>800</option>
                        <option>1000</option>
                        <option>1200</option>
                        <option>1400</option>
                        <option>1600</option>
                    </select>
                )
            }
            case 'Domea': {
                const width = watch().items[index].width

                if (width <= 2100) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                        </select>
                    )
                } else if (width <= 2400) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                        </select>
                    )
                } else if (width <= 2600) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                        </select>
                    )
                } else if (width <= 2900) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                        </select>
                    )
                } else if (width <= 3100) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                        </select>
                    )
                } else if (width <= 3400) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>2850</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>2850</option>
                            <option>3100</option>
                        </select>
                    )
                }
            }
            case 'Гармония': {
                const width = watch().items[index].width

                if (width <= 3000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                        </select>
                    )
                } else if (width <= 3600) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                        </select>
                    )
                } else if (width <= 4000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                        </select>
                    )
                } else if (width <= 4800) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                } else if (width <= 5000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>4100</option>
                        </select>
                    )
                } else if (width <= 6000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                            <option>4350</option>
                        </select>
                    )
                } else if (width <= 7000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                            <option>4350</option>
                        </select>
                    )
                }
            }
            default:
                return (
                    <input id={`items.${index}.height`} type="text"
                           {...register(`items.${index}.height` as const,
                               {required: "Обязательное поле",
                                   min: heightBoundaries[watch().items[index].model].min,
                                   max: heightBoundaries[watch().items[index].model].max,
                                   valueAsNumber: true}
                           )} defaultValue={0} />
                )
        }

    }

    return (
       <form className="mt-10 space-y-5" onSubmit={handleSubmit(onSubmit)}>
           {articles && fields.map((item, index) => {
               return(
                   <div className="border-blue-200 border mx-4 px-2 py-3 grid grid-cols-6 rounded-md" key={item.id}>
                       <div className="input-divs">
                           <label htmlFor={`items.${index}.model`}>Модель:</label>
                           <select id={`items.${index}.model`} {...register(`items.${index}.model` as const, {required: "Обязательное поле"})} defaultValue={item.model} onChange={
                               (e) => {
                                   update(index, {
                                       model: e.target.value,
                                       control: ''
                                   })
                               }
                           }>
                               <option />
                               <option>CLASSIC</option>
                               <option>MODERN</option>
                               <option>Helix</option>
                               <option>Optima</option>
                               <option>Optima PLUS</option>
                               <option>Гармония</option>
                               <option>VIKTORY</option>
                               <option>Domea</option>
                               <option>ITALIA</option>
                               <option>ASN 130 GPZ</option>
                               <option>ASN 110 cab</option>
                               <option>ASN 130 GPZ TENS</option>
                               <option>ASN 130 Эконом</option>
                               <option>Traffico</option>
                               <option>Тент на люверсах</option>
                               <option>Выставочный стенд</option>
                           </select>
                       </div>
                       {/*If not a Выставочный стенд, then show*/}
                       {(watch().items[index].model !== 'Выставочный стенд' && watch().items[index].model) && (
                           <React.Fragment>
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.width`}>Ширина({widthBoundaries[watch().items[index].model].min} - {widthBoundaries[watch().items[index].model].max} мм):</label>
                                   <input id={`items.${index}.width`} type="text"
                                          {...register(`items.${index}.width` as const,
                                              {required: "Обязательное поле",
                                                  min: widthBoundaries[watch().items[index].model].min,
                                                  max: widthBoundaries[watch().items[index].model].max,
                                                  valueAsNumber: true}
                                          )} defaultValue={item.width || 0} />
                               </div>
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.height`}>{heightText(index)}</label>
                                   {heightTextInput(index)}
                               </div>
                           </React.Fragment>
                       )}

                       {
                           isVolanDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.volan`}>Тип волана:</label>
                                   <select id={`items.${index}.volan`} {...register(`items.${index}.volan` as const, {required: "Обязательное поле"})} defaultValue={item.volan}>
                                       <option>Без волана</option>
                                       <option>Type A</option>
                                       <option>Type B</option>
                                       <option>Type C</option>
                                       <option>Type D</option>
                                       <option>Type E</option>
                                       <option>Type F</option>
                                       <option>Type G</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isMountTypeDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.mountType`}>{mountTypeText(index)}</label>
                                   <select id={`items.${index}.mountType`} {...register(`items.${index}.mountType` as const, {required: "Обязательное поле"})} defaultValue={item.mountType}>
                                       <option />
                                       {
                                           mountTypeSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isCapDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.cap`}>Верхняя укрывающая крышка:</label>
                                   <select id={`items.${index}.cap`} {...register(`items.${index}.cap` as const)} defaultValue={item.cap}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAdsDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.ads`}>Размещение рекламы:</label>
                                   <select id={`items.${index}.ads`} {...register(`items.${index}.ads` as const)} defaultValue={item.ads}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isColorDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.color`}>Цвет профилей:</label>
                                   {
                                       watch().items[index].model === 'ASN 130 Эконом' && (
                                           <select id={`items.${index}.color`} {...register(`items.${index}.color` as const, {required: "Обязательное поле"})} defaultValue={item.color}>
                                               <option />
                                               <option value="Ral 9016(белый)">Ral 9016(белый)</option>
                                               <option value="Ral 8017(коричневый)">Ral 8017(коричневый)</option>
                                               <option value="Ral 7024(антрацит)">Ral 7024(антрацит)</option>
                                           </select>
                                       )
                                   }
                                   {
                                       watch().items[index].model !== 'ASN 130 Эконом' && (
                                           <React.Fragment>
                                               <input  autoComplete='off' type="search" id={`items.${index}.color`} list={`items.${index}.colorOptions`} {...register(`items.${index}.color` as const, {required: "Обязательное поле"})} defaultValue={item.color}/>
                                               <datalist id={`items.${index}.colorOptions`}>
                                                   {
                                                       colorSelect(index)
                                                   }
                                               </datalist>
                                           </React.Fragment>
                                       )
                                   }
                               </div>
                           )
                       }

                       {
                           isFactoryDisplayed(index) && (
                               <React.Fragment>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.factory`}>Производитель тента:</label>
                                       <select id={`items.${index}.factory`} {...register(`items.${index}.factory` as const, {required: "Обязательное поле"})} defaultValue={
                                           watch().items[index].model !== 'ASN 130 GPZ' || 'ASN 110 cab' || 'ASN 130 GPZ TENS' ? 'Dickson акриловые ткани': item.factory
                                       }>
                                           {
                                               factorySelect(index)
                                           }
                                       </select>
                                   </div>
                                   {
                                       tentArticleSelect(index)
                                   }
                               </React.Fragment>
                           )
                       }

                       {
                           watch().items[index].factory === 'COPACO' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.article2`}>Сторона ткани в изделии:</label>
                                   <select id={`items.${index}.article2`} {...register(`items.${index}.article2` as const, {required: "Обязательное поле"})} defaultValue={item.article2}>
                                       {
                                           watch().items[index].model === 'Тент на люверсах' ?
                                               <React.Fragment>
                                                   <option />
                                                   <option>Back со стороны люверса</option>
                                                   <option>Front со стороны люверса</option>
                                               </React.Fragment>
                                                :
                                               <React.Fragment>
                                                   <option />
                                                   <option>Back со стороны короба</option>
                                                   <option>Front со стороны короба</option>
                                               </React.Fragment>
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].model === 'Traffico' && watch().items[index].width >= 6000 && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.additionalDriver`}>Дополнительный привод:</label>
                                   <select
                                       id={`items.${index}.additionalDriver`} {...register(`items.${index}.additionalDriver` as const, {required: "Обязательное поле"})}
                                       defaultValue={item.additionalDriver}>
                                       <option />
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].model === 'Traffico' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.triangle`}>Заполнение треугольник:</label>
                                   <select
                                       id={`items.${index}.triangle`} {...register(`items.${index}.triangle` as const, {required: "Обязательное поле"})}
                                       defaultValue={item.triangle}>
                                       <option />
                                       <option>-</option>
                                       <option>С одной стороны</option>
                                       <option>С двух сторон</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isControlDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.control`}>Управление:</label>
                                   <select
                                       id={`items.${index}.control`} {...register(`items.${index}.control` as const, {required: "Обязательное поле"})}
                                       defaultValue={item.control}>
                                       {
                                           controlSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isControlSideDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlSide`}>Сторона управления:</label>
                                   <select id={`items.${index}.controlSide`} {...register(`items.${index}.controlSide` as const, {required: "Обязательное поле"})} defaultValue={item.controlSide}>
                                       <option />
                                       <option>Справа</option>
                                       <option>Слева</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].control === 'Автоматическое' && watch().items[index].model !== 'Traffico' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlsFactory`}>Производитель привода:</label>
                                   <select id={`items.${index}.controlsFactory`} {...register(`items.${index}.controlsFactory` as const, {required: "Обязательное поле"})} defaultValue={item.controlsFactory}>
                                       {
                                           controlsFactorySelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].control === 'Автоматическое' && watch().items[index].model === 'ASN 130 GPZ' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.alarm`}>Аварийное руч. открывание:</label>
                                   <select
                                       id={`items.${index}.alarm`} {...register(`items.${index}.alarm` as const, {required: "Обязательное поле"})}
                                       defaultValue={item.alarm}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           (watch().items[index].controlsFactory === 'Somfy' || (watch().items[index].controlsFactory === 'Cherubini' && watch().items[index].model !== 'ASN 110 cab' && watch().items[index].model !== 'ASN 130 GPZ')) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlAcceptor`}>Вид привода:</label>
                                   <select id={`items.${index}.controlAcceptor`} {...register(`items.${index}.controlAcceptor` as const, {required: "Обязательное поле"})} defaultValue={item.controlAcceptor}>
                                       {
                                           controlAcceptorSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isLEDDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.led`}>LED:</label>
                                   <select id={`items.${index}.led`} {...register(`items.${index}.led` as const)} defaultValue={item.led}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                            watch().items[index].model === 'Traffico' && watch().items[index].led === 'Добавить' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.travers`}>Кол-во траверс:</label>
                                   <select id={`items.${index}.travers`} {...register(`items.${index}.travers` as const, {required: "Обязательное поле"})} defaultValue={item.travers}>
                                       {
                                           traverseSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].model === 'ASN 130 Эконом' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.vorotok`}>Вороток:</label>
                                   <select id={`items.${index}.vorotok`} {...register(`items.${index}.vorotok` as const, {required: "Обязательное поле"})} defaultValue={item.vorotok}>
                                       <option />
                                       <option>Без воротка</option>
                                       <option>Вороток 1.5 м</option>
                                       <option>Вороток 2 м</option>
                                       <option>Вороток 2.5 м</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].model === 'ASN 130 Эконом' && watch().items[index].vorotok !== 'Без воротка' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.vorotokQuantity`}>Кол-во воротков:</label>
                                   <input id={`items.${index}.vorotokQuantity`} type="text" {...register(`items.${index}.vorotokQuantity` as const, {valueAsNumber: true})} defaultValue={item.vorotokQuantity || ''} />
                               </div>
                           )
                       }

                       {
                           isAutomaticFactoryDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.automaticFactory`}>Производитель автоматики:</label>
                                   <select id={`items.${index}.automaticFactory`} {...register(`items.${index}.automaticFactory` as const, {required: "Обязательное поле"})} defaultValue={item.automaticFactory}>
                                       {
                                           automaticFactorySelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isMiniServerDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.server`}>Мини сервер:</label>
                                   <select id={`items.${index}.server`} {...register(`items.${index}.server` as const, {required: "Обязательное поле"})} defaultValue={item.server}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isSwbDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.swb`}>Выключатель для накладного монтажа:</label>
                                   <select id={`items.${index}.swb`} {...register(`items.${index}.swb` as const)} defaultValue={item.swb}>
                                       {
                                           swbSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isPilotQuantityDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.pilotQuantity`}>Кол-во пультов:</label>
                                   <input id={`items.${index}.pilotQuantity`} type="text" {...register(`items.${index}.pilotQuantity` as const, {valueAsNumber: true})} defaultValue={item.pilotQuantity || ''} />
                               </div>
                           )
                       }

                       {
                           isPilotTypeDisplayed(index)&& (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.pilotType`}>Вид пультов:</label>
                                   <select id={`items.${index}.pilotType`} {...register(`items.${index}.pilotType` as const)} defaultValue={item.pilotType}>
                                       {
                                           pultTypeSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAutomaticAdditionDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.automaticAddition`}>Дополнительная автоматика:</label>
                                   <select id={`items.${index}.automaticAddition`} {...register(`items.${index}.automaticAddition` as const)} defaultValue={item.automaticAddition}>
                                       {
                                           automaticAdditionSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAntiWindDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.antiWind`}>Антиветровая стойка:</label>
                                   <select id={`items.${index}.antiWind`} {...register(`items.${index}.antiWind` as const)} defaultValue={item.antiWind}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isRainSensorDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.rainSensor`}>Датчик дождя:</label>
                                   <select id={`items.${index}.rainSensor`} {...register(`items.${index}.rainSensor` as const)} defaultValue={item.rainSensor}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].model === 'Тент на люверсах' && (
                               <React.Fragment>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.zip`}>Длина молни (метры):</label>
                                       <input id={`items.${index}.zip`} type="text" {...register(`items.${index}.zip` as const, {required: "Обязательное поле", valueAsNumber: true})} defaultValue={item.zip || ''} />
                                   </div>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.belts`}>Количество ремешков:</label>
                                       <input id={`items.${index}.belts`} type="text" {...register(`items.${index}.belts` as const, {valueAsNumber: true})} defaultValue={item.belts || ''} />
                                   </div>
                               </React.Fragment>
                           )
                       }
                       <div className="input-divs">
                           <label htmlFor={`items.${index}.notes`}>Примечание:</label>
                           <input id={`items.${index}.notes`} {...register(`items.${index}.notes` as const )} defaultValue={item.notes}/>
                       </div>
                       <div className="flex">
                           <button className="self-center bg-red-500 hover:bg-red-600 text-white font-bold py-2.5 px-4 ml-3 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => {remove(index)}}>X</button>
                           <button className="self-center bg-green-500 hover:bg-green-600 text-white font-bold py-2.5 ml-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => {append(watch(`items.${index}`))}}>Создать копию</button>
                       </div>
                   </div>
               )
           })}
           <div className="flex justify-center space-x-4">
               {
                   (mail === 'demo_ru@markiz.by' || mail === 'demo_kz@markiz.by') ?
                       <React.Fragment>
                           <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/user-panel'}}>Вернутся назад</Link>
                           <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => append({})}>Добавить товар</button>
                           <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Далее</button>
                       </React.Fragment>
                       :
                       <React.Fragment>
                           <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/user-panel'}}>Вернутся назад</Link>
                           <button className="disabled:bg-yellow-200 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" disabled={isSaved} onClick={SaveOrder}>Сохранить заказ</button>
                           <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => append({})}>Добавить товар</button>
                           <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Далее</button>
                       </React.Fragment>

               }
           </div>
       </form>
   )
}

export default OrderFormElements